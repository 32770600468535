import React from 'react';
import { ArrowLeft, CheckCircle, UserCircle } from 'lucide-react';

interface PathSelectionProps {
  handlePathSelect: (index: number) => void;
  setCurrentStep: (step: 'intro') => void;
}

const PathSelection: React.FC<PathSelectionProps> = ({ handlePathSelect, setCurrentStep }) => {
  const paths = [
    {
      title: "Certification Path",
      icon: <CheckCircle className="w-8 h-8 text-green-500" />,
      description: "Structured curriculum designed by experts",
      features: [
        "Industry-recognized certification",
        "Predefined course structure",
        "Regular assessments",
      ],
    },
    {
      title: "Personalized Study",
      icon: <UserCircle className="w-8 h-8 text-blue-500" />,
      description: "Custom learning experience tailored to you",
      features: [
        "Flexible learning schedule",
        "Focus on your interests",
        "Learn at your own pace",
      ],
    },
  ];

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <button 
          className="flex items-center text-blue-600 hover:text-blue-800 transition-colors"
          onClick={() => setCurrentStep('intro')}
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Introduction
        </button>
        <h2 className="text-3xl font-bold text-center">Choose Your Learning Path</h2>
        <div className="w-24"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {paths.map((path, index) => (
          <div
            key={index}
            className="border p-6 rounded-lg transition-all duration-500 ease-in-out cursor-pointer hover:shadow-lg bg-white"
            onClick={() => handlePathSelect(index)}
          >
            <div className="flex items-center gap-3 mb-4">
              {path.icon}
              <h3 className="text-2xl font-semibold">{path.title}</h3>
            </div>
            <p className="mb-4 text-gray-600">{path.description}</p>
            <ul className="list-disc list-inside mb-4 text-gray-700">
              {path.features.map((feature, fIndex) => (
                <li key={fIndex}>{feature}</li>
              ))}
            </ul>
            <button className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
              Select This Path
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PathSelection;
