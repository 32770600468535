import React, { useState, FormEvent, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { useAuth } from '../components/AuthContext';
import { ShieldCheck } from 'lucide-react';

const QuizMasterLoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    try {
      const response = await axios.post(`${config.API_URL}/auth/login`, { username, password }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      console.log('Login response:', response.data);
      if (response.data.success) {
        setIsAuthenticated(true);
        navigate('/dashboard');
      } else {
        setError(response.data.message || 'An error occurred during login');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data.message || 'An error occurred during login');
      } else {
        setError('An error occurred during login');
      }
      console.error('Login error:', error);
    }
  };

  return (
    <div className="max-w-6xl mx-auto overflow-hidden">
      <div className="flex flex-col md:flex-row items-center justify-between py-12">
        <div className="w-full md:w-1/2 text-center">
          <div className="flex items-center justify-center mb-6">
            <ShieldCheck className="w-24 h-24 text-blue-500" />
            <span className="text-4xl font-bold text-blue-500 ml-2">Certit</span>
          </div>
          <h1 className="text-4xl font-bold mb-4">Cyber Security Education</h1>
          <p className="text-xl mb-8">Empower your future in the digital world. Learn the skills to protect, defend, and secure.</p>
        </div>
        <div className="w-full md:w-1/2 mt-8 md:mt-0">
          <div className="bg-white p-8 rounded-lg shadow-md max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-6 text-center">Log In to start learning</h2>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">
                  Username:
                </label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                  Password:
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white rounded-full py-2 px-4 hover:bg-blue-700 transition-colors"
              >
                Log In
              </button>
            </form>
            <p className="mt-4 text-center">
              Don't have an account?{' '}
              <Link to="/register" className="text-blue-600 hover:text-blue-800">
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizMasterLoginPage;
