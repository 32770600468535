import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ShieldCheck } from 'lucide-react';
import CybersecurityProgressChart from './CybersecurityProgressChart';

interface IntroSectionProps {
  animateIntro: boolean;
  setCurrentStep: (step: 'paths') => void;
}

const IntroSection: React.FC<IntroSectionProps> = ({ animateIntro, setCurrentStep }) => {
  const navigate = useNavigate();

  return (
    <div className={`transition-opacity duration-1000 ease-in-out ${animateIntro ? 'opacity-100' : 'opacity-0'}`}>
      <div className="flex flex-col-reverse md:flex-row items-center justify-between py-6 md:py-8">
        <div className="w-full md:w-1/2 mt-8 md:mt-0">
          <CybersecurityProgressChart />
        </div>
        <div className="w-full md:w-1/2 text-center">
          <div className="flex items-center justify-center mb-6">
            <ShieldCheck className="w-24 h-24 text-blue-500" />
            <span className="text-4xl font-bold text-blue-500 ml-2">Certit</span>
          </div>
          <h1 className="text-4xl font-bold mb-4">Cyber Security Education</h1>
          <p className="text-xl mb-8">Empower your future in the digital world. Learn the skills to protect, defend, and secure.</p>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <button 
              className="px-6 py-3 bg-blue-600 text-white rounded-full text-lg hover:bg-blue-700 transition-colors"
              onClick={() => setCurrentStep('paths')}
            >
              Get Started
            </button>
            <button 
              className="px-6 py-3 bg-gray-200 text-gray-700 rounded-full text-lg hover:bg-gray-300 transition-colors"
              onClick={() => navigate('/login')}
            >
              I already have an account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
