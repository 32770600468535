// src/components/BulletsSlide.tsx
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { BulletsSlide as BulletsSlideType } from '../types';

interface BulletsSlideProps {
  slide: BulletsSlideType;
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  height: 100%;
  background-color: #f0f0f0;
  animation: ${fadeIn} 0.5s ease-in;
`;

const Title = styled.h2`
  font-size: clamp(1.8rem, 3.5vw, 3rem);
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
`;

const ContentBox = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: calc(100% - 4rem);
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  font-size: clamp(1.2rem, 2.5vw, 2rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BulletItem = styled.li`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateX(5px);
  }
`;

const BulletPoint = styled.span`
  margin-right: 0.75rem;
  color: #4CAF50;
  font-size: 1.2em;
  font-weight: bold;
`;

const BulletsSlide: React.FC<BulletsSlideProps> = ({ slide }) => {
  return (
    <SlideContainer>
      <Title>{slide.title}</Title>
      <ContentBox>
        <BulletList>
          {slide.bullets.map((bullet, index) => (
            <BulletItem key={index}>
              <BulletPoint>•</BulletPoint>
              {bullet}
            </BulletItem>
          ))}
        </BulletList>
      </ContentBox>
    </SlideContainer>
  );
};

export default BulletsSlide;
