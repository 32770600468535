// frontend/src/App.tsx
import React, { useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Landing from './components/Landing';
import UserProfile from './components/UserProfile';
import QuizMasterLoginPage from './components/QuizMasterLoginPage';
import QuizMasterRegisterPage from './components/QuizMasterRegisterPage';
import QuizMasterUserDashboard from './components/QuizMasterUserDashboard';
import QuizMasterCybersecurityCategories from './components/QuizMasterCybersecurityCategories';
import QuizMasterCertifications from './components/QuizMasterCertifications';
import QuizMasterLeaderboard from './components/QuizMasterLeaderboard';
import QuizMasterCert from './components/QuizMasterCert';
import QuizMasterTakeQuiz from './components/QuizMasterTakeQuiz';
import QuizResults from './components/QuizResults';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorBoundary from './components/ErrorBoundary';
import About from './components/About';
import config from './config';
import { AuthProvider, useAuth } from './components/AuthContext';
import './App.css';
import CertificationGenerator from './components/CertificationGenerator';
import CheckEmailPage from './components/CheckEmailPage';
import AllCertifications from './components/AllCertifications';
import LearnerProfilePage from './components/LearnerProfilePage';
import Slideshow from './components/Slideshow';
import DomainKeyConcepts from './components/DomainKeyConcepts';
import KeyConceptView from './components/KeyConceptView';
import MatchingGame from './components/MatchingGame';
import CVSSInfoGraphic from './components/CVSSInfoGraphic';
import CVSSInfoGraphicGame from './components/CVSSChallengeGame';
import FlashCards from './components/FlashCards';

axios.defaults.withCredentials = true;

function AppContent() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${config.API_URL}/auth/check_auth`);
        console.log('Check auth response:', response.data);
        setIsAuthenticated(response.data.authenticated);
      } catch (error) {
        console.error('Check auth error:', error);
        setIsAuthenticated(false);
      }
    };
    checkAuth();
  }, [setIsAuthenticated]);

  if (isAuthenticated === null) {
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/check-email" element={<CheckEmailPage />} />
          <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Landing />} />
          <Route path="/login" element={<QuizMasterLoginPage />} />
          <Route path="/register" element={<QuizMasterRegisterPage />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/00admin/certifications"
            element={isAuthenticated ? <CertificationGenerator /> : <Navigate to="/login" />}
          />
          <Route path="/slideshow/:conceptId" element={<Slideshow />} />
          <Route path="/learners" element={<LearnerProfilePage />} />
          <Route path="/flashcards" element={<FlashCards />} />

          <Route path="/certifications" element={<AllCertifications />} />
          <Route
            path="/dashboard"
            element={isAuthenticated ? <QuizMasterUserDashboard /> : <Navigate to="/login" />}
          />
          <Route path="/matching" element={<MatchingGame />} />
          <Route path="/info-graphic" element={<CVSSInfoGraphic />} />
          <Route path="/info-quiz" element={<CVSSInfoGraphicGame />} />
          <Route
            path="/leaderboard"
            element={isAuthenticated ? <QuizMasterLeaderboard /> : <Navigate to="/login" />}
          />
          <Route path="/quiz/:id/results" element={<QuizResults />} />
          <Route path="/quiz/take" element={<QuizMasterTakeQuiz />} />
          <Route path="/certifications/:categoryId" element={<QuizMasterCertifications />} />
          <Route path="/cert/:certId" element={<QuizMasterCert />} />
          <Route
            path="/profile"
            element={isAuthenticated ? <UserProfile /> : <Navigate to="/login" />}
          />
          <Route path="/domain/:domainId" element={<DomainKeyConcepts />} />
          <Route path="/key-concept/:conceptId" element={<KeyConceptView />} />
        </Routes>
      </div>
    </Router>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
