// src/styles.ts
import React from 'react';

export const commonStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column' as const,
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  padding: '2rem',
  boxSizing: 'border-box',
};

export const slideContainerStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  margin: 0,
  padding: 0,
  background: 'linear-gradient(to right, #3b82f6, #8b5cf6)',
  overflow: 'hidden',
};