import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import Header from './Header';
import { FaUser, FaMedal, FaCog, FaBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

interface UserProfileData {
  id: number;
  username: string;
  email: string;
  is_pro: boolean;
  total_quizzes_taken: number;
  average_score: number;
  total_quizzes_created: number;
  xp: number;
  streak: number;
  longest_streak: number;
  user_mode: string;
}

interface StudyDomain {
  id: number;
  name: string;
}

interface Certification {
  id: number;
  name: string;
}

const studyDomainsList = [
  'Network', 'App Sec', 'Cloud', 'Data', 'Identity', 'Ops', 'Incident',
  'Forensics', 'Threat', 'Risk', 'Compliance', 'Crypto', 'Web Security', 'Malware Analysis'
];

const studyDomainsMap = studyDomainsList.reduce((acc, domain, index) => {
  acc[domain] = index + 1;
  return acc;
}, {} as Record<string, number>);

const UserProfile: React.FC = () => {
  const [userProfile, setUserProfile] = useState<UserProfileData | null>(null);
  const [userStudyDomains, setUserStudyDomains] = useState<string[]>([]);
  const [userMode, setUserMode] = useState<string>('cert_mode');
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [activeCertId, setActiveCertId] = useState<number | null>(null);

  const fetchCertifications = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/user/certifications`, {
        withCredentials: true
      });
      setCertifications(response.data);
    } catch (error) {
      console.error('Error fetching certifications:', error);
      toast.error('Failed to fetch certifications');
    }
  };

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/user/profile`, {
        withCredentials: true
      });
      setUserProfile(response.data);
      setUserMode(response.data.user_mode);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      toast.error('Failed to fetch user profile');
    }
  };

  const setActiveCertification = async (certId: number) => {
    try {
      await axios.post(`${config.API_URL}/user/set-active-cert`, {
        cert_id: certId
      }, {
        withCredentials: true
      });
      setActiveCertId(certId);
      toast.success('Active certification updated successfully');
    } catch (error) {
      console.error('Error setting active certification:', error);
      toast.error('Failed to set active certification');
    }
  };

  const fetchUserStudyDomains = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/user/user-study-domains`, {
        withCredentials: true
      });
      setUserStudyDomains(response.data.map((domain: StudyDomain) => domain.name));
    } catch (error) {
      console.error('Error fetching user study domains:', error);
      toast.error('Failed to fetch study domains');
    }
  };

  const handleStudyDomainChange = async (domain: string) => {
    const updatedDomains = userStudyDomains.includes(domain)
      ? userStudyDomains.filter(d => d !== domain)
      : [...userStudyDomains, domain];
    
    const domainIds = updatedDomains.map(d => studyDomainsMap[d]).filter(id => id !== undefined);
    

    if (domainIds.length === 0) {
      toast.error('No valid domains selected');
      return;
    }

    try {
      await axios.post(`${config.API_URL}/user/user-study-domains`, {
        domain_ids: domainIds
      }, {
        withCredentials: true
      });
      setUserStudyDomains(updatedDomains);
      toast.success('Study domains updated successfully');
    } catch (error) {
      console.error('Error updating study domains:', error);
      toast.error('Failed to update study domains');
    }
  };

  const toggleUserMode = async () => {
    try {
      const newMode = userMode === 'cert_mode' ? 'free_study' : 'cert_mode';
      const response = await axios.post(
        `${config.API_URL}/user/toggle-mode`,
        { mode: newMode },
        { withCredentials: true }
      );
      setUserMode(response.data.new_mode);
      toast.success(`Switched to ${response.data.new_mode === 'cert_mode' ? 'Certification' : 'Free Study'} mode`);
    } catch (error) {
      console.error('Error toggling user mode:', error);
      toast.error('Failed to switch mode');
    }
  };

  useEffect(() => {
    fetchUserProfile();
    fetchUserStudyDomains();
    fetchCertifications();
  }, []);

  return (
    <div style={{fontFamily: 'Open Sans, sans-serif', color: '#333333', backgroundColor: '#F8F8F8'}}>
      <Header />
      <main style={{maxWidth: '1200px', margin: '2rem auto', padding: '0 1rem'}}>
        <h2 style={{fontFamily: 'Montserrat, sans-serif', color: '#4A90E2'}}>
          My Profile
        </h2>
        {userProfile && (
          <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '2rem', marginBottom: '2rem'}}>
            {/* User Information Section */}
            <div style={{backgroundColor: 'white', padding: '1.5rem', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'}}>
              <h3 style={{fontFamily: 'Montserrat, sans-serif', color: '#50E3C2', marginTop: 0, marginBottom: '1.5rem', display: 'flex', alignItems: 'center'}}>
                <FaUser style={{marginRight: '0.5rem'}} /> User Information
              </h3>
              <div style={{marginBottom: '1rem'}}>
                <strong>Username:</strong> {userProfile.username}
              </div>
              <div style={{marginBottom: '1rem'}}>
                <strong>Email:</strong> {userProfile.email}
              </div>
              <div style={{marginBottom: '1rem'}}>
                <strong>Account Type:</strong> {userProfile.is_pro ? 'Pro' : 'Free'}
              </div>
              {userProfile.is_pro && (
                <div style={{backgroundColor: '#F5A623', color: 'white', padding: '0.5rem', borderRadius: '4px', display: 'inline-block'}}>
                  Pro User
                </div>
              )}
            </div>

            {/* Achievements Section */}
            <div style={{backgroundColor: 'white', padding: '1.5rem', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'}}>
              <h3 style={{fontFamily: 'Montserrat, sans-serif', color: '#4A90E2', marginTop: 0, marginBottom: '1.5rem', display: 'flex', alignItems: 'center'}}>
                <FaMedal style={{marginRight: '0.5rem'}} /> Achievements
              </h3>
              <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem'}}>
                <div style={{textAlign: 'center', padding: '1rem', backgroundColor: '#f0f8ff', borderRadius: '8px'}}>
                  <strong style={{display: 'block', marginBottom: '0.5rem', color: '#4A90E2'}}>XP</strong>
                  <span style={{fontSize: '1.5rem', fontWeight: 'bold'}}>{userProfile.xp}</span>
                </div>
                <div style={{textAlign: 'center', padding: '1rem', backgroundColor: '#fff0f5', borderRadius: '8px'}}>
                  <strong style={{display: 'block', marginBottom: '0.5rem', color: '#FF69B4'}}>Current Streak</strong>
                  <span style={{fontSize: '1.5rem', fontWeight: 'bold'}}>{userProfile.streak}</span>
                </div>
                <div style={{textAlign: 'center', padding: '1rem', backgroundColor: '#f0fff0', borderRadius: '8px'}}>
                  <strong style={{display: 'block', marginBottom: '0.5rem', color: '#32CD32'}}>Longest Streak</strong>
                  <span style={{fontSize: '1.5rem', fontWeight: 'bold'}}>{userProfile.longest_streak}</span>
                </div>
                <div style={{textAlign: 'center', padding: '1rem', backgroundColor: '#fff5e6', borderRadius: '8px'}}>
                  <strong style={{display: 'block', marginBottom: '0.5rem', color: '#FFA500'}}>Quizzes Created</strong>
                  <span style={{fontSize: '1.5rem', fontWeight: 'bold'}}>{userProfile.total_quizzes_created}</span>
                </div>
              </div>
            </div>

            {/* Performance Section */}
            <div style={{backgroundColor: 'white', padding: '1.5rem', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'}}>
              <h3 style={{fontFamily: 'Montserrat, sans-serif', color: '#F5A623', marginTop: 0, marginBottom: '1.5rem', display: 'flex', alignItems: 'center'}}>
                <FaCog style={{marginRight: '0.5rem'}} /> Performance
              </h3>
              <div style={{marginBottom: '1rem'}}>
                <strong>Total Quizzes Taken:</strong> {userProfile.total_quizzes_taken}
              </div>
              <div style={{marginBottom: '1rem'}}>
                <strong>Average Score:</strong> {userProfile.average_score.toFixed(2)}%
              </div>
              <div style={{marginTop: '2rem'}}>
                <Link 
                  to="/dashboard" 
                  style={{
                    backgroundColor: '#4A90E2', 
                    color: 'white', 
                    padding: '0.75rem 1.5rem', 
                    borderRadius: '5px', 
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    transition: 'background-color 0.3s ease',
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3a7bc8'}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#4A90E2'}
                >
                  View Detailed Stats
                </Link>
              </div>
            </div>
          </div>
        )}

        {/* Study Mode Section */}
        <div style={{backgroundColor: 'white', padding: '1.5rem', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', marginTop: '2rem'}}>
          <h3 style={{fontFamily: 'Montserrat, sans-serif', color: '#4A90E2', marginTop: 0, display: 'flex', alignItems: 'center'}}>
            <FaBook style={{marginRight: '0.5rem'}} /> Study Mode
          </h3>
          <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
            <span style={{marginRight: '1rem'}}>Free Study</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={userMode === 'cert_mode'}
                onChange={toggleUserMode}
              />
              <span className="slider round"></span>
            </label>
            <span style={{marginLeft: '1rem'}}>Certification</span>
          </div>
          
          {userMode === 'cert_mode' ? (
            <div>
              <h4>Active Certification</h4>
              <select
                value={activeCertId || ''}
                onChange={(e) => setActiveCertification(Number(e.target.value))}
                style={{
                  width: '100%',
                  padding: '0.5rem',
                  marginTop: '1rem',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              >
                <option value="">Select a certification</option>
                {certifications.map((cert) => (
                  <option key={cert.id} value={cert.id}>
                    {cert.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div>
              <h4>Study Domains</h4>
              <div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem', marginTop: '1rem'}}>
                {studyDomainsList.map(domain => (
                  <label key={domain} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                    <input
                      type="checkbox"
                      checked={userStudyDomains.includes(domain)}
                      onChange={() => handleStudyDomainChange(domain)}
                      style={{marginRight: '0.5rem'}}
                    />
                    {domain}
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Account Settings Section */}
        <div style={{backgroundColor: 'white', padding: '1.5rem', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', marginTop: '2rem'}}>
          <h3 style={{fontFamily: 'Montserrat, sans-serif', color: '#4A90E2', marginTop: 0}}>Account Settings</h3>
          <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
            <Link 
              to="/change-password" 
              style={{
                backgroundColor: '#f0f0f0', 
                color: '#333', 
                padding: '0.75rem 1.5rem', 
                borderRadius: '5px', 
                textDecoration: 'none',
                fontWeight: 'bold',
                transition: 'background-color 0.3s ease',
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
            >
              Change Password
            </Link>
            <Link 
              to="/edit-profile" 
              style={{
                backgroundColor: '#f0f0f0', 
                color: '#333', 
                padding: '0.75rem 1.5rem', 
                borderRadius: '5px', 
                textDecoration: 'none',
                fontWeight: 'bold',
                transition: 'background-color 0.3s ease',
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#e0e0e0'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
            >
              Edit Profile
            </Link>
            {userProfile && !userProfile.is_pro && (
              <Link 
                to="/pro-signup" 
                style={{
                  backgroundColor: '#50E3C2', 
                  color: 'white', 
                  padding: '0.75rem 1.5rem', 
                  borderRadius: '5px', 
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3cd1b0'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#50E3C2'}
              >
                Upgrade to Pro
              </Link>
            )}
          </div>
        </div>
      </main>
      <footer style={{backgroundColor: '#333333', color: 'white', padding: '2rem 1rem', marginTop: '2rem'}}>
        <div style={{maxWidth: '1200px', margin: '0 auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
          <p>&copy; 2024 Certit. All rights reserved.</p>
          <nav>
            <ul style={{display: 'flex', listStyle: 'none', gap: '1rem', margin: 0, padding: 0}}>
              <li><a href="#about" style={{color: 'white', textDecoration: 'none'}}>About Us</a></li>
              <li><a href="#contact" style={{color: 'white', textDecoration: 'none'}}>Contact</a></li>
              <li><a href="#privacy" style={{color: 'white', textDecoration: 'none'}}>Privacy Policy</a></li>
              <li><a href="#terms" style={{color: 'white', textDecoration: 'none'}}>Terms of Service</a></li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default UserProfile;
