// frontend/src/components/AllCertifications.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import CertificationPath from './CertificationPath';
import Header from './Header';
import Footer from './Footer';

const AllCertifications: React.FC = () => {
  const [certifications, setCertifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchAllCertifications();
  }, []);

  const fetchAllCertifications = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${config.API_URL}/quiz/certifications`, {
        withCredentials: true
      });
      setCertifications(response.data);
    } catch (error) {
      console.error('Error fetching certifications:', error);
      setError('Failed to fetch certifications. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ fontFamily: 'Open Sans, sans-serif', color: '#333333', backgroundColor: '#F8F8F8' }}>
      <Header />
      <div className="max-w-6xl mx-auto px-4 py-4">
        <h1 className="text-3xl font-bold mb-6">All Certifications</h1>
        <CertificationPath
          certifications={certifications}
          loading={loading}
          error={error}
          setCurrentStep={() => {}}
        />
      </div>
      <Footer />
    </div>
  );
};

export default AllCertifications;
