import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import config from '../config';
import { Slide, KeyConcept, FlowchartSlide as FlowchartSlideType } from '../types';
import { useSlideTransition } from '../hooks/useSlideTransition';
import Header from './Header';
import PieChartSlide from './PieChartSlide';

// Import slide components
import TitleSlide from './TitleSlide';
import BulletsSlide from './BulletsSlide';
import ImageSlide from './ImageSlide';
import SplitSlide from './SplitSlide';
import QuoteSlide from './QuoteSlide';
import ComparisonSlide from './ComparisonSlide';
import FlowchartSlide from './FlowchartSlide';

const Slideshow: React.FC = () => {
  const { conceptId } = useParams<{ conceptId: string }>();
  const [slides, setSlides] = useState<Slide[]>([]);
  const [keyConcept, setKeyConcept] = useState<KeyConcept | null>(null);
  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const navigate = useNavigate();

  const {
    currentIndex,
    nextIndex,
    currentSlideStyle,
    nextSlideStyle,
    isTransitioning,
    nextSlide,
    prevSlide,
    goToSlide,
    setCurrentIndex,
  } = useSlideTransition(slides, false);

  useEffect(() => {
    console.log('Fetching slides for conceptId:', conceptId);
    const fetchSlides = async () => {
      try {
        setLoading(true);
        setSlides([]);
        setKeyConcept(null);

        const response = await axios.get(`${config.API_URL}/slides/${conceptId}`);
        const fetchedSlides = response.data.slides;
        console.log('Fetched slides:', fetchedSlides);
        const finalSlide: Slide = {
          id: fetchedSlides.length,
          type: 'final',
          index: fetchedSlides.length,
          title: 'What would you like to do next?'
        };
        setSlides([...fetchedSlides, finalSlide]);
        setKeyConcept(response.data.key_concept);
        setLoading(false);
        setRetryCount(0);
        setCurrentIndex(0);
      } catch (error) {
        console.error('Error fetching slides:', error);
        if (retryCount < 3) {
          setTimeout(() => {
            setRetryCount(prevCount => prevCount + 1);
          }, 1000);
        } else {
          setLoading(false);
        }
      }
    };

    fetchSlides();
  }, [conceptId, setCurrentIndex]);

  const handleNextSlideshow = () => {
    const nextConceptId = parseInt(conceptId!) + 1;
    navigate(`/slideshow/${nextConceptId}`);
  };

  const renderSlideContent = (slide: Slide | undefined) => {
    if (!slide) {
      console.log('No slide to render');
      return null;
    }

    console.log('Rendering slide:', slide.type);

    switch (slide.type) {
      case 'title':
        return <TitleSlide slide={slide} />;
      case 'bullets':
        return <BulletsSlide slide={slide} />;
      case 'image':
        return <ImageSlide slide={slide} />;
      case 'split':
        return <SplitSlide slide={slide} />;
      case 'quote':
        return <QuoteSlide slide={slide} />;
      case 'comparison':
        return <ComparisonSlide slide={slide} />;
      case 'flowchart':
        return <FlowchartSlide slide={slide as FlowchartSlideType} />;
      case 'pie_chart':
        if ('mermaid_syntax' in slide) {
          return <PieChartSlide slide={slide} />;
        } else {
          console.error('Pie chart slide is missing mermaid_syntax:', slide);
          return null;
        }   
      case 'pie':
            if ('mermaid_syntax' in slide) {
              return <PieChartSlide slide={slide} />;
            } else {
              console.error('Pie chart slide is missing mermaid_syntax:', slide);
              return null;
            }     
      case 'final':
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <h2 style={{ fontSize: '3rem', marginBottom: '2rem', color: '#333333' }}>{slide.title}</h2>
            <div style={{ display: 'flex', gap: '2rem' }}>
            <Link
              to="/dashboard"
              style={{
                fontSize: '1.5rem',
                padding: '1rem 2rem',
                background: '#4A90E2',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
            >
              Home
            </Link>
            <Link
              to={`/quiz/take?concept_id=${conceptId}&num_questions=10`}
              style={{
                fontSize: '1.5rem',
                padding: '1rem 2rem',
                background: '#F39C12',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
            >
              Quiz
            </Link>
            <button
              onClick={handleNextSlideshow}
              style={{
                fontSize: '1.5rem',
                padding: '1rem 2rem',
                background: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Next
            </button>
            </div>
          </div>
        );
      default:
        console.log('Unknown slide type:', (slide as Slide).type);
        return null;
    }
  };

  const renderSlides = () => {
    const currentSlide = slides[currentIndex];
    const nextSlide = nextIndex !== null ? slides[nextIndex] : null;

    return (
      <>
        <div style={{ ...currentSlideStyle, position: 'absolute', inset: 0 }}>
          {renderSlideContent(currentSlide)}
        </div>
        {nextSlide && (
          <div style={{ ...nextSlideStyle, position: 'absolute', inset: 0 }}>
            {renderSlideContent(nextSlide)}
          </div>
        )}
      </>
    );
  };

  if (loading) {
    return <div>Loading slides...</div>;
  }

  if (slides.length === 0) {
    return <div>No slides available.</div>;
  }

  return (
    <div style={{fontFamily: 'Open Sans, sans-serif', color: '#333333', backgroundColor: '#F8F8F8'}}>
      <Header />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#4A90E2',
        padding: '1rem',
        marginBottom: '.25rem',
      }}>
        {/* <h1 style={{
          fontFamily: 'Montserrat, sans-serif',
          color: 'white',
          fontSize: 'clamp(1.25rem, 4vw, 2.5rem)',
          margin: '0 0 0.5rem 0',
          padding: '0',
          textAlign: 'center',
          maxWidth: '100%',
          wordWrap: 'break-word',
        }}>
          {keyConcept?.name || 'Slideshow'}
        </h1> */}
      </div>
      <main style={{maxWidth: '1200px', margin: '2rem auto', padding: '0 1rem'}}>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: 'calc(100vh - 200px)',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            overflow: 'hidden',
          }}
        >
          {renderSlides()}
          {currentIndex < slides.length - 1 && (
            <button
              onClick={nextSlide}
              style={{
                position: 'absolute',
                right: '5%',
                bottom: '5%',
                background: 'rgba(74, 144, 226, 0.8)',
                color: 'white',
                borderRadius: '50%',
                width: '60px',
                height: '60px',
                border: 'none',
                cursor: 'pointer',
                zIndex: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'background 0.3s',
              }}
            >
              <FaChevronDown size={24} />
            </button>
          )}
          {currentIndex > 0 && (
            <button
              onClick={prevSlide}
              style={{
                position: 'absolute',
                left: '5%',
                top: '5%',
                background: 'rgba(74, 144, 226, 0.8)',
                color: 'white',
                borderRadius: '50%',
                width: '60px',
                height: '60px',
                border: 'none',
                cursor: 'pointer',
                zIndex: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'background 0.3s',
              }}
            >
              <FaChevronUp size={24} />
            </button>
          )}
          <div
            style={{
              position: 'absolute',
              bottom: '1rem',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              gap: '0.5rem',
              zIndex: 10,
            }}
          >
            {slides.map((_, index) => (
              <div
                key={index}
                onClick={() => goToSlide(index)}
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  background: index === currentIndex ? '#4A90E2' : 'rgba(74, 144, 226, 0.3)',
                  cursor: 'pointer',
                  transition: 'background 0.3s',
                }}
              />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Slideshow;
