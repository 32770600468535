import React, { useRef, useEffect, useState } from 'react';
import { ComparisonSlide as ComparisonSlideType } from '../types';
import { commonStyles } from '../styles';

interface ComparisonSlideProps {
  slide: ComparisonSlideType;
}

const ComparisonSlide: React.FC<ComparisonSlideProps> = ({ slide }) => {
  const [fontSize, setFontSize] = useState('1.2rem');
  const contentRef = useRef<HTMLDivElement>(null);
  const leftColumnRef = useRef<HTMLDivElement>(null);
  const rightColumnRef = useRef<HTMLDivElement>(null);

  const slideStyles: React.CSSProperties = {
    ...commonStyles,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '1rem',
    justifyContent: 'center',
  };

  const mainContentStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
  };

  const titleStyles: React.CSSProperties = {
    fontSize: 'clamp(1.5rem, 3vw, 2.5rem)',
    fontWeight: 'bold',
    marginBottom: '1rem',
    textAlign: 'center',
  };

  const contentContainerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flexGrow: 1,
    maxHeight: 'calc(90vh - 4rem)',
  };

  const sectionStyles: React.CSSProperties = {
    width: '45%',
    padding: '1rem',
    border: '2px solid #333',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  };

  const sectionTitleStyles: React.CSSProperties = {
    fontSize: 'clamp(1.2rem, 2vw, 1.8rem)',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    textAlign: 'center',
  };

  const listStyles: React.CSSProperties = {
    listStyleType: 'none',
    padding: 0,
    fontSize: fontSize,
    flexGrow: 1,
  };

  const vsIndicatorStyles: React.CSSProperties = {
    fontSize: 'clamp(1.5rem, 3vw, 2.5rem)',
    fontWeight: 'bold',
    margin: '0 1rem',
    alignSelf: 'center',
  };

  useEffect(() => {
    if (contentRef.current && leftColumnRef.current && rightColumnRef.current) {
      const containerHeight = contentRef.current.clientHeight;
      const leftHeight = leftColumnRef.current.scrollHeight;
      const rightHeight = rightColumnRef.current.scrollHeight;
      const maxHeight = Math.max(leftHeight, rightHeight);
      const ratio = containerHeight / maxHeight;

      if (ratio > 1) {
        setFontSize(`${Math.min(ratio * 1.2, 1.8)}rem`);
      } else {
        setFontSize('1.2rem');
      }

      // Set equal height for both columns
      const equalHeight = Math.min(maxHeight, containerHeight);
      leftColumnRef.current.style.height = `${equalHeight}px`;
      rightColumnRef.current.style.height = `${equalHeight}px`;
    }
  }, [slide]);

  return (
    <div style={slideStyles}>
      <div style={mainContentStyles}>
        <h2 style={titleStyles}>{slide.title}</h2>
        <div style={contentContainerStyles} ref={contentRef}>
          <div style={sectionStyles} ref={leftColumnRef}>
            <h3 style={sectionTitleStyles}>{slide.leftTitle}</h3>
            <ul style={listStyles}>
              {slide.leftPoints.map((point, index) => (
                <li key={index} style={{ marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '0.5rem', color: '#4CAF50', fontSize: '1.2em' }}>•</span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
          <div style={vsIndicatorStyles}>VS</div>
          <div style={sectionStyles} ref={rightColumnRef}>
            <h3 style={sectionTitleStyles}>{slide.rightTitle}</h3>
            <ul style={listStyles}>
              {slide.rightPoints.map((point, index) => (
                <li key={index} style={{ marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '0.5rem', color: '#4CAF50', fontSize: '1.2em' }}>•</span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonSlide;
