import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Header from './Header';
import { 
  FaNetworkWired, FaShieldAlt, FaCloud, FaDatabase, FaUserLock, 
  FaCog, FaFireExtinguisher, FaSearch, FaBinoculars, 
  FaBalanceScale, FaClipboardCheck, FaLock, FaBug, FaCertificate
} from 'react-icons/fa';

interface Certification {
  id: number;
  name: string;
  description: string;
  icon: string;
  color: string;
}

const iconMap: { [key: string]: React.ComponentType } = {
  'network-wired': FaNetworkWired,
  'shield-alt': FaShieldAlt,
  'cloud': FaCloud,
  'database': FaDatabase,
  'user-lock': FaUserLock,
  'cogs': FaCog,
  'fire-extinguisher': FaFireExtinguisher,
  'search': FaSearch,
  'binoculars': FaBinoculars,
  'balance-scale': FaBalanceScale,
  'clipboard-check': FaClipboardCheck,
  'lock': FaLock,
  'bug': FaBug,
  'certificate': FaCertificate
};

const QuizMasterCertifications: React.FC = () => {
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { categoryId } = useParams<{ categoryId: string }>();
  const navigate = useNavigate();

  const fetchCertifications = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${config.API_URL}/quiz/certifications/${categoryId}`, {
        withCredentials: true
      });
      setCertifications(response.data);
    } catch (error) {
      console.error('Error fetching certifications:', error);
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        navigate('/login');
      } else {
        setError('Failed to fetch certifications. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  }, [categoryId, navigate]);

  useEffect(() => {
    fetchCertifications();
  }, [fetchCertifications]);

  const handleCertificationClick = (certId: number) => {
    navigate(`/cert/${certId}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{fontFamily: 'Open Sans, sans-serif', color: '#333333', backgroundColor: '#F8F8F8'}}>
      <Header />
      <main style={{maxWidth: '1200px', margin: '2rem auto', padding: '0 1rem'}}>
        <h2 style={{fontFamily: 'Montserrat, sans-serif', color: '#4A90E2', textAlign: 'center', marginBottom: '2rem'}}>Certifications</h2>
        
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
          gap: '1rem'
        }}>
          {certifications.map((cert) => {
            const IconComponent = iconMap[cert.icon] || FaCertificate;
            return (
              <div key={cert.id} className="certification-card" onClick={() => handleCertificationClick(cert.id)} style={{
                cursor: 'pointer',
                padding: '1rem',
                backgroundColor: 'white',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              }}>
                <div style={{
                  fontSize: '2rem',
                  marginBottom: '0.5rem',
                  backgroundColor: cert.color || '#4A90E2',
                  color: 'white',
                  width: '60px',
                  height: '60px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <IconComponent />
                </div>
                <h3 style={{fontFamily: 'Montserrat, sans-serif', color: '#4A90E2', marginTop: 0, fontSize: '1rem'}}>
                  {cert.name}
                </h3>
                <p style={{marginTop: '0.5rem', fontSize: '0.8rem', height: '40px', overflow: 'hidden'}}>{cert.description}</p>
              </div>
            );
          })}
        </div>
      </main>
    </div>
  );
};

export default QuizMasterCertifications;
