import React, { useState, useEffect } from 'react';
import { Shield, Clock, Settings, AlertTriangle, Lock } from 'lucide-react';

interface MetricGroup {
  name: string;
  color: string;
  icon: React.ElementType;
}

interface Vulnerability {
  description: string;
  group: string;
}

const CVSSChallengeGame: React.FC = () => {
  const [activeGroup, setActiveGroup] = useState<string | null>(null);
  const [currentVulnerability, setCurrentVulnerability] = useState<Vulnerability | null>(null);
  const [score, setScore] = useState<number>(0);
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(60);

  const metricGroups: MetricGroup[] = [
    {
      name: 'Base Metrics',
      color: '#3B82F6',
      icon: Shield,
    },
    {
      name: 'Temporal Metrics',
      color: '#F97316',
      icon: Clock,
    },
    {
      name: 'Environmental Metrics',
      color: '#22C55E',
      icon: Settings,
    },
    {
      name: 'Impact Metrics',
      color: '#EF4444',
      icon: AlertTriangle,
    },
    {
      name: 'Defense Metrics',
      color: '#8B5CF6',
      icon: Lock,
    }
  ];

  const vulnerabilities: Vulnerability[] = [
    { description: "A buffer overflow in the network stack", group: "Base Metrics" },
    { description: "Exploit code is widely available", group: "Temporal Metrics" },
    { description: "The vulnerability affects a custom-configured server", group: "Environmental Metrics" },
    { description: "The vulnerability could lead to significant financial losses", group: "Impact Metrics" },
    { description: "Intrusion detection systems can detect exploitation attempts", group: "Defense Metrics" },
    { description: "The vulnerability requires user interaction to exploit", group: "Base Metrics" },
    { description: "A patch has been released but not widely adopted", group: "Temporal Metrics" },
    { description: "The affected system has high availability requirements", group: "Environmental Metrics" },
    { description: "Exploiting the vulnerability could cause reputational damage", group: "Impact Metrics" },
    { description: "The organization has implemented strong access controls", group: "Defense Metrics" },
  ];

  useEffect(() => {
    if (!gameOver) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setGameOver(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [gameOver]);

  useEffect(() => {
    if (!currentVulnerability && !gameOver) {
      setCurrentVulnerability(vulnerabilities[Math.floor(Math.random() * vulnerabilities.length)]);
    }
  }, [currentVulnerability, gameOver]);

  const handleGroupClick = (groupName: string) => {
    if (currentVulnerability && currentVulnerability.group === groupName) {
      setScore(score + 1);
      setCurrentVulnerability(null);
    } else {
      setScore(Math.max(0, score - 1));
    }
    setActiveGroup(groupName);
  };

  const getPosition = (index: number, total: number): { x: number; y: number } => {
    const angle = ((index * (360 / total)) - 90) * (Math.PI / 180);
    const radius = 200;
    return {
      x: Math.cos(angle) * radius + 250,
      y: Math.sin(angle) * radius + 250
    };
  };

  const restartGame = () => {
    setScore(0);
    setGameOver(false);
    setRemainingTime(60);
    setCurrentVulnerability(null);
  };

  return (
    <div className="w-full h-screen bg-gray-100 flex items-center justify-center">
      <div className="relative w-[800px] h-[800px] bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-bold text-center mb-2">CVSS Challenge</h1>
        <h2 className="text-xl text-center mb-4">Match the vulnerability to the correct metric group!</h2>
        <div className="text-center mb-4">
          <p className="text-lg font-bold">Score: {score}</p>
          <p className="text-lg font-bold">Time: {remainingTime}s</p>
        </div>
        {gameOver ? (
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Game Over!</h2>
            <p className="text-xl mb-4">Final Score: {score}</p>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={restartGame}
            >
              Play Again
            </button>
          </div>
        ) : (
          <div className="text-center mb-8">
            <p className="text-lg font-bold">Current Vulnerability:</p>
            <p className="text-md">{currentVulnerability?.description}</p>
          </div>
        )}
        <svg width="100%" height="70%" viewBox="0 0 500 500">
          {/* Central CVSS Logo */}
          <circle cx="250" cy="250" r="40" fill="#E5E7EB" />
          <text x="250" y="250" textAnchor="middle" dominantBaseline="middle" fontSize="20" fontWeight="bold">CVSS</text>
          {/* Metric Groups */}
          {metricGroups.map((group, index) => {
            const { x, y } = getPosition(index, metricGroups.length);
            return (
              <g key={group.name} onClick={() => !gameOver && handleGroupClick(group.name)}>
                <circle
                  cx={x}
                  cy={y}
                  r={50}
                  fill={group.color}
                  className={`cursor-pointer transition-all duration-300 ${activeGroup === group.name ? 'stroke-white stroke-2' : ''}`}
                />
                <foreignObject x={x-25} y={y-25} width={50} height={50}>
                  <div className="flex items-center justify-center h-full">
                    <group.icon className="w-6 h-6 text-white" />
                  </div>
                </foreignObject>
                <text x={x} y={y+70} textAnchor="middle" fill="black" fontSize="12" fontWeight="500">{group.name}</text>
              </g>
            );
          })}
        </svg>
      </div>
    </div>
  );
};

export default CVSSChallengeGame;
