import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'outline';
}

export const Button: React.FC<ButtonProps> = ({ 
  children, 
  variant = 'default', 
  className = '',
  ...props 
}) => {
  const baseStyles = 'px-4 py-2 rounded font-medium focus:outline-none focus:ring-2 focus:ring-opacity-50';
  const variantStyles = 
    variant === 'default' 
      ? 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500' 
      : 'border border-blue-500 text-blue-500 hover:bg-blue-50 focus:ring-blue-500';

  return (
    <button 
      className={`${baseStyles} ${variantStyles} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
