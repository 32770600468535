import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config';

const CertificationGenerator: React.FC = () => {
  const [certificationName, setCertificationName] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerate = async () => {
    if (!certificationName) {
      toast.error('Please enter a certification name');
      return;
    }

    setIsGenerating(true);
    try {
      const response = await axios.post(
        `${config.API_URL}/quiz/generate_certification`,
        { certification_name: certificationName },
        { withCredentials: true }
      );
      toast.success('Certification generated successfully');
      setCertificationName('');
    } catch (error) {
      toast.error('Failed to generate certification');
      console.error('Error generating certification:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="certification-generator">
      <h2>Generate New Certification</h2>
      <div className="input-group">
        <input
          type="text"
          value={certificationName}
          onChange={(e) => setCertificationName(e.target.value)}
          placeholder="Enter certification name"
          disabled={isGenerating}
        />
        <button onClick={handleGenerate} disabled={isGenerating}>
          {isGenerating ? 'Generating...' : 'Generate'}
        </button>
      </div>
    </div>
  );
};

export default CertificationGenerator;
