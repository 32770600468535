import React, { useState } from 'react';
import { ArrowLeft } from 'lucide-react';
import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar } from 'recharts';
import { useNavigate } from 'react-router-dom';

interface PersonalizedStudyProps {
  setCurrentStep: (step: 'paths') => void;
}

const PersonalizedStudy: React.FC<PersonalizedStudyProps> = ({ setCurrentStep }) => {
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleSetStudyDomains = () => {
    const domainsParam = encodeURIComponent(JSON.stringify(selectedDomains));
    navigate(`/register?domains=${domainsParam}`);
  };

  const formatPerformanceData = () => {
    return selectedDomains.map(domain => ({
      subject: domain,
      A: 0,
      fullMark: 100,
    }));
  };

  const domains = [
    "Network", "App Sec", "Cloud", "Data", "Identity", "Ops", "Incident",
    "Forensics", "Threat", "Risk", "Compliance", "Crypto", "Web Security", "Malware Analysis"
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center justify-between mb-6">
        <button 
          className="flex items-center text-blue-600 hover:text-blue-800 transition-colors"
          onClick={() => setCurrentStep('paths')}
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back
        </button>
        <h2 className="text-2xl md:text-3xl font-bold">Personalized Study</h2>
        <div className="w-24"></div>
      </div>

      <div className="flex flex-col md:flex-row md:space-x-8">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
          <h3 className="text-xl font-semibold mb-4">Your Study Radar</h3>
          <div className="w-full h-64 md:h-96">
            {selectedDomains.length < 3 ? (
              <div className="h-full flex items-center justify-center text-center p-4 bg-blue-100 rounded-lg">
                <div>
                  <p className="text-blue-700 font-semibold">
                    Select at least 3 domains to see your personalized study radar.
                  </p>
                  <p className="text-blue-600 mt-2">
                    {3 - selectedDomains.length} more {3 - selectedDomains.length === 1 ? 'domain' : 'domains'} needed.
                  </p>
                </div>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={formatPerformanceData()}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" tick={{ fontSize: 10, fill: '#333' }} tickLine={false} />
                  <PolarRadiusAxis angle={30} domain={[0, 100]} tick={{ fontSize: 10, fill: '#333' }} />
                  <Radar name="Performance" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                </RadarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        <div className="w-full md:w-1/2">
          <h3 className="text-xl font-semibold mb-4">Select Your Study Domains</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
            {domains.map((domain, index) => (
              <button
                key={index}
                className={`p-2 rounded-lg text-sm transition-colors ${
                  selectedDomains.includes(domain)
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => {
                  if (selectedDomains.includes(domain)) {
                    setSelectedDomains(selectedDomains.filter(d => d !== domain));
                  } else {
                    setSelectedDomains([...selectedDomains, domain]);
                  }
                }}
              >
                {domain}
              </button>
            ))}
          </div>
        </div>
      </div>
      
      <div className="mt-8 text-center">
        <button
          className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={handleSetStudyDomains}
          disabled={selectedDomains.length < 3}
        >
          Register for Free
        </button>
      </div>
    </div>
  );
};

export default PersonalizedStudy;
