import React, { useRef, useEffect, useState } from 'react';
import { SplitSlide as SplitSlideType } from '../types';
import { commonStyles } from '../styles';

interface SplitSlideProps {
  slide: SplitSlideType;
}

const SplitSlide: React.FC<SplitSlideProps> = ({ slide }) => {
  const [fontSize, setFontSize] = useState('1.2rem');
  const contentRef = useRef<HTMLDivElement>(null);

  const splitStyles: React.CSSProperties = {
    ...commonStyles,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '1rem',
    justifyContent: 'center',
  };

  const mainContentStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
  };

  const titleStyles: React.CSSProperties = {
    fontSize: 'clamp(1.5rem, 3vw, 2.5rem)',
    fontWeight: 'bold',
    marginBottom: '1rem',
    textAlign: 'center',
  };

  const contentContainerStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    maxHeight: 'calc(90vh - 4rem)', // Adjust based on title size
  };

  const sectionStyles: React.CSSProperties = {
    width: '48%',
    padding: '.2rem',
    border: '2px solid #333',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflowY: 'auto',
  };

  const sectionTitleStyles: React.CSSProperties = {
    fontSize: 'clamp(1.2rem, 2vw, 1.8rem)',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    textAlign: 'center',
  };

  const listStyles: React.CSSProperties = {
    listStyleType: 'none',
    padding: 0,
    fontSize: fontSize,
    flexGrow: 1,
  };

  useEffect(() => {
    if (contentRef.current) {
      const containerHeight = contentRef.current.clientHeight;
      const contentHeight = contentRef.current.scrollHeight;
      const ratio = containerHeight / contentHeight;

      if (ratio > 1) {
        setFontSize(`${Math.min(ratio * 1.2, 1.8)}rem`);
      } else {
        setFontSize('1.2rem');
      }
    }
  }, [slide]);

  const renderContent = (content: string) => {
    const lines = content.split('\n');
    const title = lines[0].trim();
    const bulletPoints = lines.slice(1).map(line => line.trim().replace(/^-\s*/, ''));

    return (
      <>
        <h3 style={sectionTitleStyles}>{title}</h3>
        <ul style={listStyles}>
          {bulletPoints.map((point, index) => (
            <li key={index} style={{ marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '0.5rem', color: '#4CAF50', fontSize: '1.2em' }}>•</span>
              {point}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div style={splitStyles}>
      <div style={mainContentStyles}>
        <h2 style={titleStyles}>{slide.title}</h2>
        <div style={contentContainerStyles} ref={contentRef}>
          <div style={sectionStyles}>
            {renderContent(slide.leftContent)}
          </div>
          <div style={sectionStyles}>
            {renderContent(slide.rightContent)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitSlide;
