// src/hooks/useSlideTransition.ts
import { useState, useEffect, useCallback } from 'react';
import { Slide } from '../types';

export const useSlideTransition = (slides: Slide[], isAutoPlaying: boolean) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState<number | null>(null);
  const [transition, setTransition] = useState<'none' | 'slideUp' | 'slideDown'>('none');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentSlideStyle, setCurrentSlideStyle] = useState<React.CSSProperties>({});
  const [nextSlideStyle, setNextSlideStyle] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (nextIndex !== null && nextIndex !== currentIndex) {
      if (transition === 'slideUp') {
        setCurrentSlideStyle({ transform: 'translateY(0%)', transition: 'none' });
        setNextSlideStyle({ transform: 'translateY(100%)', transition: 'none' });
      } else if (transition === 'slideDown') {
        setCurrentSlideStyle({ transform: 'translateY(0%)', transition: 'none' });
        setNextSlideStyle({ transform: 'translateY(-100%)', transition: 'none' });
      }

      setTimeout(() => {
        if (transition === 'slideUp') {
          setCurrentSlideStyle({
            transform: 'translateY(-100%)',
            transition: 'transform 0.5s ease-in-out',
          });
          setNextSlideStyle({
            transform: 'translateY(0%)',
            transition: 'transform 0.5s ease-in-out',
          });
        } else if (transition === 'slideDown') {
          setCurrentSlideStyle({
            transform: 'translateY(100%)',
            transition: 'transform 0.5s ease-in-out',
          });
          setNextSlideStyle({
            transform: 'translateY(0%)',
            transition: 'transform 0.5s ease-in-out',
          });
        }
      }, 50);

      setTimeout(() => {
        setCurrentIndex(nextIndex);
        setNextIndex(null);
        setTransition('none');
        setCurrentSlideStyle({});
        setNextSlideStyle({});
        setIsTransitioning(false);
      }, 550);
    }
  }, [nextIndex, transition, currentIndex]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isAutoPlaying) {
      intervalId = setInterval(() => {
        nextSlide();
      }, 5000);
    }
    return () => clearInterval(intervalId);
  }, [isAutoPlaying, currentIndex]);

  const nextSlide = useCallback(() => {
    if (!isTransitioning && slides.length > 0) {
      setIsTransitioning(true);
      const newIndex = (currentIndex + 1) % slides.length;
      setNextIndex(newIndex);
      setTransition('slideUp');
    }
  }, [currentIndex, isTransitioning, slides.length]);

  const prevSlide = useCallback(() => {
    if (!isTransitioning && slides.length > 0) {
      setIsTransitioning(true);
      const newIndex = (currentIndex - 1 + slides.length) % slides.length;
      setNextIndex(newIndex);
      setTransition('slideDown');
    }
  }, [currentIndex, isTransitioning, slides.length]);

  const goToSlide = useCallback((index: number) => {
    if (index !== currentIndex && !isTransitioning) {
      setTransition(index > currentIndex ? 'slideUp' : 'slideDown');
      setNextIndex(index);
      setIsTransitioning(true);
    }
  }, [currentIndex, isTransitioning]);

  return {
    currentIndex,
    nextIndex,
    currentSlideStyle,
    nextSlideStyle,
    isTransitioning,
    nextSlide,
    prevSlide,
    goToSlide,
    setIsTransitioning,
    setTransition,
    setCurrentIndex,
  };
};
