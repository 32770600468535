import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import Button from '../components/ui/button';
import { ScrollArea } from '../components/ui/scroll-area';

interface UserProfile {
  name: string;
  age: number;
  education: string;
  background: string;
  goal: string;
  learningStyle: string;
}

interface LearnerProfileSelectorProps {
  profiles: UserProfile[];
  onSelect: (profile: UserProfile) => void;
}

const LearnerProfileSelector: React.FC<LearnerProfileSelectorProps> = ({ profiles, onSelect }) => {
  const [selectedProfile, setSelectedProfile] = useState<UserProfile | null>(null);

  const handleSelect = (profile: UserProfile) => {
    setSelectedProfile(profile);
    onSelect(profile);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Select a Learner Profile</h2>
      <ScrollArea className="h-[70vh]">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {profiles.map((profile, index) => (
            <Card key={index} className={`cursor-pointer transition-all ${selectedProfile === profile ? 'ring-2 ring-blue-500' : ''}`}>
              <CardHeader>
                <CardTitle>{profile.name}, {profile.age}</CardTitle>
                <CardDescription>{profile.education}</CardDescription>
              </CardHeader>
              <CardContent>
                <p><strong>Background:</strong> {profile.background}</p>
                <p><strong>Goal:</strong> {profile.goal}</p>
                <p><strong>Learning Style:</strong> {profile.learningStyle}</p>
              </CardContent>
              <CardFooter>
                <Button onClick={() => handleSelect(profile)} variant={selectedProfile === profile ? "default" : "outline"}>
                  {selectedProfile === profile ? "Selected" : "Select"}
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};

export default LearnerProfileSelector;