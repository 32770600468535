import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Header from './Header';

const QuizMasterRegisterPage: React.FC = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [domains, setDomains] = useState<string[]>([]);
  const [anonymousId, setAnonymousId] = useState<string | null>(null);
  const [certificationId, setCertificationId] = useState<string | null>(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const domainsParam = searchParams.get('domains');
    const anonymousIdParam = searchParams.get('anonymous_id');
    const certIdParam = searchParams.get('cert_id');

    if (domainsParam) {
      try {
        const parsedDomains = JSON.parse(decodeURIComponent(domainsParam));
        setDomains(parsedDomains);
      } catch (error) {
        console.error('Error parsing domains:', error);
      }
    }
    if (anonymousIdParam) {
      setAnonymousId(anonymousIdParam);
    }
    if (certIdParam) {
      setCertificationId(certIdParam);
    }
  }, [location]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    try {
      const response = await axios.post(`${config.API_URL}/auth/register`, {
        ...formData,
        domains,
        anonymous_id: anonymousId,
        certification_id: certificationId
      });

      if (response.data.success) {
        navigate('/check-email');
      } else {
        setError(response.data.message || 'Registration failed');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data.message || 'Registration failed');
      } else {
        setError('An error occurred during registration');
      }
    }
  };

  return (
    <div style={{fontFamily: 'Open Sans, sans-serif', color: '#333333', backgroundColor: '#F8F8F8', minHeight: '100vh'}}>
      <Header />
      <main style={{maxWidth: '600px', margin: '2rem auto', padding: '0 1rem'}}>
        <h2 style={{fontFamily: 'Montserrat, sans-serif', color: '#4A90E2', textAlign: 'center'}}>Create Your Certit Account</h2>
        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        <form onSubmit={handleSubmit} style={{backgroundColor: 'white', padding: '2rem', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'}}>
          <div style={{marginBottom: '1rem'}}>
            <label htmlFor="username" style={{display: 'block', marginBottom: '0.5rem', fontWeight: 'bold'}}>Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              style={{width: '100%', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px'}}
            />
          </div>
          <div style={{marginBottom: '1rem'}}>
            <label htmlFor="email" style={{display: 'block', marginBottom: '0.5rem', fontWeight: 'bold'}}>Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              style={{width: '100%', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px'}}
            />
          </div>
          <div style={{marginBottom: '1rem'}}>
            <label htmlFor="password" style={{display: 'block', marginBottom: '0.5rem', fontWeight: 'bold'}}>Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              style={{width: '100%', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px'}}
            />
          </div>
          <div style={{marginBottom: '1rem'}}>
            <label htmlFor="confirmPassword" style={{display: 'block', marginBottom: '0.5rem', fontWeight: 'bold'}}>Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              style={{width: '100%', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px'}}
            />
          </div>
          {domains.length > 0 && (
            <div style={{marginBottom: '1rem'}}>
              <h3 style={{fontFamily: 'Montserrat, sans-serif', color: '#4A90E2'}}>Selected Study Domains:</h3>
              <ul style={{listStyleType: 'none', padding: 0}}>
                {domains.map((domain, index) => (
                  <li key={index} style={{marginBottom: '0.5rem'}}>{domain}</li>
                ))}
              </ul>
            </div>
          )}
          {certificationId && (
            <div style={{marginBottom: '1rem'}}>
              <h3 style={{fontFamily: 'Montserrat, sans-serif', color: '#4A90E2'}}>Selected Certification:</h3>
              <p>Certification ID: {certificationId}</p>
            </div>
          )}
          <button type="submit" style={{
            backgroundColor: '#F5A623',
            color: 'white',
            padding: '0.75rem 1.5rem',
            borderRadius: '5px',
            border: 'none',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 500,
            cursor: 'pointer',
            width: '100%'
          }}>
            Register
          </button>
        </form>
        <p style={{textAlign: 'center', marginTop: '1rem'}}>
          Already have an account? <Link to="/login" style={{color: '#4A90E2', textDecoration: 'none'}}>Log in</Link>
        </p>
      </main>
      <footer style={{backgroundColor: '#333333', color: 'white', padding: '2rem 1rem', marginTop: '2rem'}}>
        <div style={{maxWidth: '1200px', margin: '0 auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
          <p>&copy; 2024 Certit. All rights reserved.</p>
          <nav>
            <ul style={{display: 'flex', listStyle: 'none', gap: '1rem', margin: 0, padding: 0}}>
              <li><a href="#about" style={{color: 'white', textDecoration: 'none'}}>About Us</a></li>
              <li><a href="#contact" style={{color: 'white', textDecoration: 'none'}}>Contact</a></li>
              <li><a href="#privacy" style={{color: 'white', textDecoration: 'none'}}>Privacy Policy</a></li>
              <li><a href="#terms" style={{color: 'white', textDecoration: 'none'}}>Terms of Service</a></li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default QuizMasterRegisterPage;
