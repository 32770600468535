import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Header from './Header';
import Footer from './Footer';
import IntroSection from './IntroSection';
import PathSelection from './PathSelection';
import CertificationPath from './CertificationPath';
import PersonalizedStudy from './PersonalizedStudy';

const LandingPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<'intro' | 'paths' | 'cert' | 'personalized'>('intro');
  const [animateIntro, setAnimateIntro] = useState<boolean>(false);
  const [selectedPath, setSelectedPath] = useState<number | null>(null);
  const [certifications, setCertifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setAnimateIntro(true);
    fetchAllCertifications();
  }, []);

  const fetchAllCertifications = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${config.API_URL}/quiz/certifications`, {
        withCredentials: true
      });
      setCertifications(response.data);
    } catch (error) {
      console.error('Error fetching certifications:', error);
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        navigate('/login');
      } else {
        setError('Failed to fetch certifications. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePathSelect = (index: number) => {
    setSelectedPath(index);
    setCurrentStep(index === 0 ? 'cert' : 'personalized');
  };

  const renderStep = () => {
    switch (currentStep) {
      case 'intro':
        return <IntroSection animateIntro={animateIntro} setCurrentStep={setCurrentStep} />;
      case 'paths':
        return <PathSelection handlePathSelect={handlePathSelect} setCurrentStep={setCurrentStep} />;
      case 'cert':
        return (
          <CertificationPath
            certifications={certifications}
            loading={loading}
            error={error}
            setCurrentStep={setCurrentStep}
          />
        );
      case 'personalized':
        return <PersonalizedStudy setCurrentStep={setCurrentStep} />;
    }
  };

  return (
    <div style={{ fontFamily: 'Open Sans, sans-serif', color: '#333333', backgroundColor: '#F8F8F8' }}>
      <Header />
      <div className="max-w-6xl mx-auto px-4 py-4">
        {renderStep()}
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
