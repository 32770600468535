import React from 'react';
import Header from './Header'; // Import the Header component

const QuizMasterLeaderboard = () => {
  const overallLeaders = [
    { rank: 1, username: 'QuizWizard', score: 9850 },
    { rank: 2, username: 'TriviaKing', score: 9720 },
    { rank: 3, username: 'BrainiacQueen', score: 9680 },
    { rank: 4, username: 'KnowledgeNinja', score: 9550 },
    { rank: 5, username: 'QuizChampion', score: 9490 },
  ];

  const categoryLeaders = {
    Science: ['EinsteinFan', 'QuantumQuizzer', 'ChemistryChamp'],
    History: ['TimeTraveler', 'AncientExplorer', 'HistoryBuff'],
    Literature: ['BookwormPro', 'ShakespeareScholar', 'NovelNerd'],
    'Pop Culture': ['CelebrityGuru', 'MovieMaster', 'TrendSetter'],
  };

  return (
    <div style={{fontFamily: 'Open Sans, sans-serif', color: '#333333', backgroundColor: '#F8F8F8'}}>
      <Header /> {/* Use the Header component here */}
      <main style={{maxWidth: '1200px', margin: '2rem auto', padding: '0 1rem'}}>
        <h2 style={{fontFamily: 'Montserrat, sans-serif', color: '#4A90E2', textAlign: 'center'}}>QuizMaster Leaderboard</h2>
        <div style={{backgroundColor: 'white', padding: '1.5rem', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', marginBottom: '2rem'}}>
          <h3 style={{fontFamily: 'Montserrat, sans-serif', color: '#50E3C2', marginTop: 0}}>Overall Top Performers</h3>
          <table style={{width: '100%', borderCollapse: 'collapse'}}>
            <thead>
              <tr style={{backgroundColor: '#f0f0f0'}}>
                <th style={{padding: '0.5rem', textAlign: 'left'}}>Rank</th>
                <th style={{padding: '0.5rem', textAlign: 'left'}}>Username</th>
                <th style={{padding: '0.5rem', textAlign: 'right'}}>Score</th>
              </tr>
            </thead>
            <tbody>
              {overallLeaders.map((leader) => (
                <tr key={leader.rank} style={{borderBottom: '1px solid #eee'}}>
                  <td style={{padding: '0.5rem'}}>{leader.rank}</td>
                  <td style={{padding: '0.5rem'}}>{leader.username}</td>
                  <td style={{padding: '0.5rem', textAlign: 'right'}}>{leader.score}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '2rem'}}>
          {Object.entries(categoryLeaders).map(([category, leaders]) => (
            <div key={category} style={{backgroundColor: 'white', padding: '1.5rem', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)'}}>
              <h3 style={{fontFamily: 'Montserrat, sans-serif', color: '#F5A623', marginTop: 0}}>{category} Leaders</h3>
              <ol style={{paddingLeft: '1.5rem'}}>
                {leaders.map((leader, index) => (
                  <li key={index} style={{padding: '0.25rem 0'}}>{leader}</li>
                ))}
              </ol>
            </div>
          ))}
        </div>
        <div style={{backgroundColor: 'white', padding: '1.5rem', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', marginTop: '2rem'}}>
          <h3 style={{fontFamily: 'Montserrat, sans-serif', color: '#4A90E2', marginTop: 0}}>Your Ranking</h3>
          <p>Your current overall ranking: <strong>#42</strong></p>
          <p>Keep quizzing to improve your rank!</p>
          <button style={{backgroundColor: '#F5A623', color: 'white', padding: '0.75rem 1.5rem', borderRadius: '5px', border: 'none', marginTop: '1rem', fontFamily: 'Roboto, sans-serif', fontWeight: 500, cursor: 'pointer'}}>
            Take a Quiz Now
          </button>
        </div>
      </main>
      <footer style={{backgroundColor: '#333333', color: 'white', padding: '2rem 1rem', marginTop: '2rem'}}>
        <div style={{maxWidth: '1200px', margin: '0 auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
          <p>&copy; 2024 Certit. All rights reserved.</p>
          <nav>
            <ul style={{display: 'flex', listStyle: 'none', gap: '1rem', margin: 0, padding: 0}}>
              <li><a href="#about" style={{color: 'white', textDecoration: 'none'}}>About Us</a></li>
              <li><a href="#contact" style={{color: 'white', textDecoration: 'none'}}>Contact</a></li>
              <li><a href="#privacy" style={{color: 'white', textDecoration: 'none'}}>Privacy Policy</a></li>
              <li><a href="#terms" style={{color: 'white', textDecoration: 'none'}}>Terms of Service</a></li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default QuizMasterLeaderboard;
