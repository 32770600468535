import React from 'react';
import { ShieldCheck, Book, Users, Trophy, Cpu } from 'lucide-react';

const About: React.FC = () => {
  const sections = [
    {
      title: "Our Mission",
      icon: <ShieldCheck className="w-8 h-8 text-blue-500" />,
      content: "At Certit, we're pioneering a new frontier in cybersecurity education. Our mission is to provide cutting-edge, personalized learning experiences that prepare individuals for the dynamic challenges of the digital security landscape."
    },
    {
      title: "Our Innovative Approach",
      icon: <Cpu className="w-8 h-8 text-green-500" />,
      content: "Certit leverages the power of artificial intelligence and adaptive learning algorithms to create a truly unique educational experience. We use AI-driven systems to analyze each learner's needs, generate custom curricula, and provide personalized learning paths. Our Computerized Adaptive Testing (CAT) and exam simulations ensure accurate skill assessment and prepare students for certification tests."
    },
    {
      title: "Key Features",
      icon: <Book className="w-8 h-8 text-purple-500" />,
      content: "Our adaptive learning system includes dynamic question selection, real-time difficulty adjustment, comprehensive skill evaluation, efficient learning paths, and simulated exam experiences. These features combine to provide a learning experience that's not just effective, but truly transformative."
    },
    {
      title: "Our Commitment",
      icon: <Trophy className="w-8 h-8 text-yellow-500" />,
      content: "As a new entrant in the cybersecurity education space, we're committed to pushing the boundaries of what's possible in online learning. By harnessing the power of AI and adaptive algorithms, we aim to provide a learning experience that's engaging, efficient, and tailored to each individual's needs."
    }
  ];

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">About Certit</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Revolutionizing Cybersecurity Education
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Discover how Certit is transforming cybersecurity learning through AI-driven adaptive education.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            {sections.map((section) => (
              <div key={section.title} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  {section.icon}
                  {section.title}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{section.content}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default About;