import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Header from './Header';
import Confetti from 'react-confetti';
import { FaCheck, FaTimes, FaFilter } from 'react-icons/fa';
import { useAuth } from './AuthContext';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaTrophy } from 'react-icons/fa';

interface Question {
  id: number;
  text: string;
  options: string[];
  correct_answer: string;
  correct_answer_text: string;
  explanation?: string;
  user_answer: string | null;
  is_correct: boolean;
  domain: string;
  domain_id: number;
}

interface QuizResult {
  quiz_id: number;
  certification_id: number | null;
  certification_name: string;
  correct_answers: number;
  total_questions: number;
  percentage_score: number;
  questions: Question[];
  xp: number | null;
  lives: number | null;
  streak: number | null;
}

const calculateStats = (questions: Question[]) => {
  const total = questions.length;
  const correct = questions.filter(q => q.is_correct).length;
  const incorrect = total - correct;
  const accuracy = total > 0 ? (correct / total) * 100 : 0;
  return { total, correct, incorrect, accuracy };
};

const getNextQuizDomain = (userPerformance: Record<string, any>): number => {
  if (!userPerformance || Object.keys(userPerformance).length === 0) {
    return 1; // Default to the first domain if no performance data
  }
  const sortedDomains = Object.values(userPerformance).sort((a, b) => {
    // Sort first by coverage, then by accuracy
    if (a.domain_coverage !== b.domain_coverage) {
      return a.domain_coverage - b.domain_coverage;
    }
    return a.accuracy - b.accuracy;
  });
  return sortedDomains[0].domain_id;
};

const QuizResults: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [result, setResult] = useState<QuizResult | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [filter, setFilter] = useState<'all' | 'correct' | 'incorrect'>('incorrect');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const questionsPerPage = 5;
  const [userPerformance, setUserPerformance] = useState<Record<string, any> | null>(null);
  const [quizPerformance, setQuizPerformance] = useState<any>(null);

  const nextQuizDomainId = useMemo(() => {
    return getNextQuizDomain(userPerformance || {});
  }, [userPerformance]);

  useEffect(() => {
    const fetchQuizResults = async () => {
      setIsLoading(true);
      try {
        const anonymousId = localStorage.getItem('anonymousId');
        const resultsResponse = await axios.get(`${config.API_URL}/quiz/${id}/results`, {
          withCredentials: true,
          params: { anonymous_id: anonymousId }
        });
        setResult(resultsResponse.data);

        // Fetch quiz performance data
        const performanceResponse = await axios.get(`${config.API_URL}/quiz/quiz_performance/${id}`, {
          withCredentials: true,
          params: {
            anonymous_id: anonymousId
          }
        });
        setQuizPerformance(performanceResponse.data);

        // Confetti logic after performance response is received
        if (performanceResponse.data.accuracy > 80) {
          setShowConfetti(true);
        }

        // Only fetch user performance if authenticated and it's a certification quiz
        if (isAuthenticated && resultsResponse.data.certification_id) {
          const userPerformanceResponse = await axios.get(`${config.API_URL}/quiz/user_performance/${resultsResponse.data.certification_id}`, {
            withCredentials: true
          });
          setUserPerformance(userPerformanceResponse.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        if (axios.isAxiosError(error)) {
          setError(`Failed to fetch data: ${error.response?.data?.message || error.message}`);
        } else {
          setError('An unexpected error occurred while fetching data');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuizResults();
  }, [id, isAuthenticated]);

  const filteredResult = useMemo(() => {
    if (!result) return null;
    const filteredQuestions = result.questions.filter((question) => {
      if (filter === 'all') {
        return true;
      } else {
        return (filter === 'correct' && question.is_correct) || (filter === 'incorrect' && !question.is_correct);
      }
    });
    return {
      ...result,
      questions: filteredQuestions,
    };
  }, [result, filter]);

  const totalPages = Math.ceil((filteredResult?.questions?.length || 0) / questionsPerPage);
  const paginatedQuestions = filteredResult?.questions?.slice(
    (currentPage - 1) * questionsPerPage,
    currentPage * questionsPerPage
  ) || [];

  if (isLoading) {
    return <div>Loading results...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!result) {
    return <div>No results available.</div>;
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={500}
        />
      )}
      <Header />
      {result && (
        <div className="max-w-4xl mx-auto p-4 sm:p-8">
          <div className="flex flex-col sm:flex-row gap-4 mb-8">
            {result.certification_id ? (
              <Link
                to={`/quiz/${result.certification_id}/take?domain_id=${nextQuizDomainId}&num_questions=10`}
                className="block w-full sm:w-auto text-center bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
              >
                Next Quiz
              </Link>
            ) : (
              <Link
                to="/quiz/generate/free_study?num_questions=10"
                className="block w-full sm:w-auto text-center bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
              >
                Next Free Study Quiz
              </Link>
            )}
            <Link
              to="/dashboard"
              className="block w-full sm:w-auto text-center bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Dashboard
            </Link>
          </div>
        </div>
      )}
      {quizPerformance && (
        <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 mt-8">
          <h3 className="text-xl font-bold text-indigo-700 mb-4">{quizPerformance.quiz_title} Performance</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="bg-blue-100 p-4 rounded-lg">
              <div className="flex items-center justify-center space-x-4">
                {quizPerformance.accuracy >= 70 && (
                  <FaTrophy
                    className={`text-[144px] ${
                      quizPerformance.accuracy >= 90 ? 'text-yellow-500' :
                      quizPerformance.accuracy >= 80 ? 'text-gray-400' :
                      'text-yellow-700'
                    }`}
                  />
                )}
                <div className="text-center">
                  <div className="w-32 h-32">
                    <CircularProgressbar
                      value={quizPerformance.accuracy}
                      text={`${quizPerformance.accuracy.toFixed(0)}%`}
                      styles={buildStyles({
                        textSize: '22px',
                        pathColor: `rgba(62, 152, 199, ${quizPerformance.accuracy / 100})`,
                        textColor: '#3e98c7',
                        trailColor: '#d6d6d6',
                      })}
                    />
                  </div>
                  <p className="text-lg font-bold text-indigo-600 mt-2">
                    {quizPerformance.correct_answers} / {quizPerformance.total_questions}
                  </p>
                  <p className="text-sm text-gray-600">Correct Answers</p>
                </div>
              </div>
            </div>
            <div className="bg-gradient-to-r from-yellow-100 via-green-100 to-red-100 p-4 rounded-lg flex flex-col h-full">
              <h4 className="text-lg font-semibold text-gray-700 mb-3">Quiz Rewards</h4>
              <div className="flex-grow flex flex-col justify-center">
                <div className="grid grid-cols-3 gap-2 h-full">
                  <div className="flex flex-col items-center justify-center">
                    <p className="text-3xl sm:text-4xl md:text-5xl font-bold text-yellow-600">{result.xp || 'N/A'}</p>
                    <p className="text-xs sm:text-sm md:text-base text-gray-600 mt-2">XP Gained</p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <p className="text-3xl sm:text-4xl md:text-5xl font-bold text-green-600">{result.streak || 'N/A'}</p>
                    <p className="text-xs sm:text-sm md:text-base text-gray-600 mt-2">Streak</p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <p className="text-3xl sm:text-4xl md:text-5xl font-bold text-red-600">{result.lives || 'N/A'}</p>
                    <p className="text-xs sm:text-sm md:text-base text-gray-600 mt-2">Lives</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="max-w-4xl mx-auto p-4 sm:p-8">
        <div className="bg-white rounded-lg shadow-lg p-4 sm:p-8 mb-8">
          <h2 className="text-3xl font-bold mb-6 text-indigo-700">
            {result.certification_id ? result.certification_name : 'Free Study'} Progress
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-8">
            <div className="bg-indigo-100 p-4 rounded-lg">
              <p className="text-lg font-semibold text-indigo-800">Score</p>
              <p className="text-3xl font-bold text-indigo-600">{result.correct_answers} / {result.total_questions}</p>
            </div>
            <div className="bg-indigo-100 p-4 rounded-lg">
              <p className="text-lg font-semibold text-indigo-800">Percentage</p>
              <p className="text-3xl font-bold text-indigo-600">{result.percentage_score.toFixed(2)}%</p>
            </div>
            <div className="bg-indigo-100 p-4 rounded-lg">
              <p className="text-lg font-semibold text-indigo-800">Quiz Type</p>
              <p className="text-xl font-bold text-indigo-600">{result.certification_id ? result.certification_name : 'Free Study'}</p>
            </div>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
            <div className="bg-indigo-600 h-2.5 rounded-full" style={{ width: `${result.percentage_score}%` }}></div>
          </div>
        </div>
        {!isAuthenticated && (
          <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mt-8" role="alert">
            <p className="font-bold">Want to track your progress?</p>
            <p>Create an account to save your quiz results and track your improvement over time!</p>
            <Link
              to={`/register?anonymous_id=${localStorage.getItem('anonymousId')}`}
              className="mt-2 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Create Account
            </Link>
          </div>
        )}
        <div className="bg-white rounded-lg shadow-lg p-4 sm:p-8">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-2xl font-bold text-indigo-700">Question Review</h3>
            <div className="flex items-center">
              <FaFilter className="mr-2" />
              <select
                className="border rounded p-2"
                value={filter}
                onChange={(e) => setFilter(e.target.value as 'all' | 'correct' | 'incorrect')}
              >
                <option value="incorrect">Incorrect Answers</option>
                <option value="all">All Questions</option>
                <option value="correct">Correct Answers</option>
              </select>
            </div>
          </div>
          {filteredResult && (
            <div className="bg-gray-100 rounded-lg p-4 mb-6">
              <h4 className="text-lg font-semibold mb-2">Summary</h4>
              <div className="grid grid-cols-3 gap-4 text-center">
                {(() => {
                  const stats = calculateStats(filteredResult.questions);
                  return (
                    <>
                      <div>
                        <p className="font-bold text-green-600">{stats.correct}</p>
                        <p className="text-sm">Correct</p>
                      </div>
                      <div>
                        <p className="font-bold text-red-600">{stats.incorrect}</p>
                        <p className="text-sm">Incorrect</p>
                      </div>
                      <div>
                        <p className="font-bold text-blue-600">{stats.accuracy.toFixed(1)}%</p>
                        <p className="text-sm">Accuracy</p>
                      </div>
                    </>
                  );
                })()}
              </div>
            </div>
          )}
          {paginatedQuestions.map((question) => (
            <div key={question.id} className={`mb-6 p-4 rounded-lg ${
              question.is_correct ? 'bg-green-100' : 'bg-red-100'
            }`}>
              <div className="flex items-start mb-2">
                {question.is_correct ? (
                  <FaCheck className="text-green-500 mr-2 mt-1" />
                ) : (
                  <FaTimes className="text-red-500 mr-2 mt-1" />
                )}
                <div>
                  <p className="font-semibold">{question.text}</p>
                  <p className="text-sm text-gray-600 mt-1">Domain: {question.domain}</p>
                </div>
              </div>
              <div className="ml-6 mt-3">
                <div className={`bg-white border-2 rounded-lg p-3 mb-2 ${
                  question.is_correct ? 'border-green-500' : 'border-red-500'
                }`}>
                  <p className={`font-medium ${question.is_correct ? 'text-green-700' : 'text-red-700'}`}>
                    {question.is_correct ? 'Your correct answer:' : 'Your answer:'}
                  </p>
                  <p className={`text-lg font-bold ${question.is_correct ? 'text-green-800' : 'text-red-800'}`}>
                    {question.user_answer
                      ? ` ${question.options[question.user_answer.charCodeAt(0) - 65]}`
                      : 'Not answered'}
                  </p>
                  {!question.is_correct && (
                    <div className="mt-2">
                      <p className="font-medium text-green-700">Correct answer:</p>
                      <p className="text-lg font-bold text-green-800">{question.correct_answer_text}</p>
                    </div>
                  )}
                </div>
                {question.explanation && (
                  <div className="bg-yellow-100 border-2 border-yellow-500 rounded-lg p-3 mb-2">
                    <p className="font-medium text-yellow-700">Explanation:</p>
                    <p className="text-gray-800">{question.explanation}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
          <div className="flex justify-center mt-4">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => setCurrentPage(i + 1)}
                className={`mx-1 px-3 py-1 rounded ${
                  currentPage === i + 1 ? 'bg-indigo-600 text-white' : 'bg-gray-200'
                }`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizResults;
