import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../components/ui/button';
import { ChevronLeft, ChevronRight, Check, X, Shuffle, Heart, Zap, CheckCircle } from 'lucide-react';
import LoadingSpinner from './LoadingSpinner';

// Card components
const Card: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className = '', ...props }) => (
  <div className={`bg-white shadow-md rounded-lg ${className}`} {...props} />
);

const CardContent: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className = '', ...props }) => (
  <div className={`px-6 py-4 ${className}`} {...props} />
);

// PortData interface
interface PortData {
  id: number;
  port: string;
  protocol: string;
  application: string;
}

const portData: PortData[] = [
  { id: 1, port: "20/21", protocol: "TCP", application: "FTP (File Transfer Protocol)" },
  { id: 2, port: "22", protocol: "TCP", application: "SSH (Secure Shell)" },
  { id: 3, port: "23", protocol: "TCP", application: "Telnet" },
  { id: 4, port: "25", protocol: "TCP", application: "SMTP (Simple Mail Transfer Protocol)" },
  { id: 5, port: "53", protocol: "TCP/UDP", application: "DNS (Domain Name System)" },
  { id: 6, port: "67/68", protocol: "UDP", application: "DHCP (Dynamic Host Configuration Protocol)" },
  { id: 7, port: "69", protocol: "UDP", application: "TFTP (Trivial File Transfer Protocol)" },
  { id: 8, port: "80", protocol: "TCP", application: "HTTP (Hypertext Transfer Protocol)" },
  { id: 9, port: "110", protocol: "TCP", application: "POP3 (Post Office Protocol v3)" },
  { id: 10, port: "119", protocol: "TCP", application: "NNTP (Network News Transfer Protocol)" },
  { id: 11, port: "123", protocol: "UDP", application: "NTP (Network Time Protocol)" },
  { id: 12, port: "137-139", protocol: "TCP/UDP", application: "NetBIOS" },
  { id: 13, port: "143", protocol: "TCP", application: "IMAP (Internet Message Access Protocol)" },
  { id: 14, port: "161/162", protocol: "UDP", application: "SNMP (Simple Network Management Protocol)" },
  { id: 15, port: "389", protocol: "TCP/UDP", application: "LDAP (Lightweight Directory Access Protocol)" },
  { id: 16, port: "443", protocol: "TCP", application: "HTTPS (HTTP Secure)" },
  { id: 17, port: "445", protocol: "TCP", application: "SMB (Server Message Block)" },
  { id: 18, port: "465", protocol: "TCP", application: "SMTPS (SMTP over SSL)" },
  { id: 19, port: "514", protocol: "UDP", application: "Syslog" },
  { id: 20, port: "587", protocol: "TCP", application: "SMTP (Submission)" },
  { id: 21, port: "631", protocol: "TCP/UDP", application: "IPP (Internet Printing Protocol)" },
  { id: 22, port: "636", protocol: "TCP", application: "LDAPS (LDAP over SSL)" },
  { id: 23, port: "993", protocol: "TCP", application: "IMAPS (IMAP over SSL)" },
  { id: 24, port: "995", protocol: "TCP", application: "POP3S (POP3 over SSL)" },
  { id: 25, port: "1433", protocol: "TCP", application: "MSSQL (Microsoft SQL Server)" },
  { id: 26, port: "1521", protocol: "TCP", application: "Oracle Database" },
  { id: 27, port: "3306", protocol: "TCP", application: "MySQL Database" },
  { id: 28, port: "3389", protocol: "TCP", application: "RDP (Remote Desktop Protocol)" },
  { id: 29, port: "5060/5061", protocol: "TCP/UDP", application: "SIP (Session Initiation Protocol)" },
  { id: 30, port: "5900", protocol: "TCP", application: "VNC (Virtual Network Computing)" },
];

const FlashcardSystem: React.FC = () => {
  const [cards, setCards] = useState<PortData[]>(portData);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [knownCards, setKnownCards] = useState<Set<number>>(new Set());
  const [direction, setDirection] = useState(0);

  const handleFlip = () => setIsFlipped(!isFlipped);

  const handleNext = () => {
    setDirection(1);
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cards.length);
    setIsFlipped(false);
  };

  const handlePrevious = () => {
    setDirection(-1);
    setCurrentCardIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
    setIsFlipped(false);
  };

  const handleMarkKnown = () => {
    setKnownCards(new Set(knownCards.add(cards[currentCardIndex].id)));
    handleNext();
  };

  const handleMarkUnknown = () => {
    const newKnownCards = new Set(knownCards);
    newKnownCards.delete(cards[currentCardIndex].id);
    setKnownCards(newKnownCards);
    handleNext();
  };

  const shuffleCards = () => {
    setCards(cards => [...cards].sort(() => Math.random() - 0.5));
    setCurrentCardIndex(0);
    setIsFlipped(false);
    setKnownCards(new Set());
  };

  const cardVariants = {
    enter: (direction: number) => ({ x: direction > 0 ? 1000 : -1000, opacity: 0 }),
    center: { x: 0, opacity: 1 },
    exit: (direction: number) => ({ x: direction < 0 ? 1000 : -1000, opacity: 0 }),
  };

  if (!cards.length) {
    return <LoadingSpinner />;
  }

  return (
    <div className="max-w-2xl mx-auto p-4 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-2">A+ Certification Port Flashcards</h1>
      <p className="text-gray-600 mb-4">Master the essential ports for your A+ Certification exam</p>
      
      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-4">
          <div className="flex items-center">
            <Zap className="text-yellow-500 mr-1" />
            <span>XP: {knownCards.size * 10}</span>
          </div>
          <div className="flex items-center">
            <Heart className="text-red-500 mr-1" />
            <span>Lives: 3</span>
          </div>
          <div className="flex items-center">
            <CheckCircle className="text-green-500 mr-1" />
            <span>Streak: {knownCards.size}</span>
          </div>
        </div>
      </div>

      <AnimatePresence mode="wait" custom={direction}>
        <motion.div
          key={currentCardIndex}
          custom={direction}
          variants={cardVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          <Card 
            className="w-96 h-56 cursor-pointer rounded-xl shadow-lg overflow-hidden"
            onClick={handleFlip}
          >
            <CardContent className="flex items-center justify-center h-full p-4 relative">
              <motion.div
                initial={false}
                animate={{ rotateY: isFlipped ? 180 : 0 }}
                transition={{ duration: 0.6, type: "spring" }}
                className="w-full h-full [transform-style:preserve-3d] flex items-center justify-center"
              >
                <div 
                  className={`absolute w-full h-full backface-hidden flex flex-col items-center justify-center 
                              ${isFlipped ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}
                >
                  <p className="text-center text-3xl font-bold text-gray-800 mb-2">
                    Port {cards[currentCardIndex].port}
                  </p>
                  <p className="text-center text-xl font-semibold text-gray-600">
                    {cards[currentCardIndex].protocol}
                  </p>
                </div>
                <div 
                  className={`absolute w-full h-full backface-hidden [transform:rotateY(180deg)] flex items-center justify-center 
                              ${isFlipped ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
                >
                  <p className="text-center text-xl font-bold text-gray-800 px-4">
                    {cards[currentCardIndex].application}
                  </p>
                </div>
              </motion.div>
            </CardContent>
          </Card>
        </motion.div>
      </AnimatePresence>

      <div className="flex space-x-4 mt-6">
        <Button onClick={handlePrevious} className="bg-white text-blue-500 hover:bg-blue-100">
          <ChevronLeft size={24} />
        </Button>
        <Button onClick={handleNext} className="bg-white text-blue-500 hover:bg-blue-100">
          <ChevronRight size={24} />
        </Button>
        <Button onClick={shuffleCards} className="bg-yellow-500 hover:bg-yellow-600 text-white">
          <Shuffle size={24} />
        </Button>
      </div>
      <div className="flex space-x-4 mt-4">
        <Button 
          onClick={handleMarkKnown} 
          className="bg-green-500 hover:bg-green-600 text-white"
        >
          <Check size={24} />
          <span className="ml-2">I know this</span>
        </Button>
        <Button 
          onClick={handleMarkUnknown} 
          className="bg-red-500 hover:bg-red-600 text-white"
        >
          <X size={24} />
          <span className="ml-2">Still learning</span>
        </Button>
      </div>
      <div className="text-lg font-semibold mt-4">
        Progress: {knownCards.size} / {cards.length}
      </div>
      <div className="w-full max-w-md bg-gray-200 rounded-full h-2.5 mt-2">
        <div 
          className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-out" 
          style={{ width: `${(knownCards.size / cards.length) * 100}%` }}
        ></div>
      </div>
    </div>
  );
};

export default FlashcardSystem;
