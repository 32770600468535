import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';
import { FlowchartSlide as FlowchartSlideType } from '../types';
import { commonStyles } from '../styles';

interface FlowchartSlideProps {
  slide: FlowchartSlideType;
}

const FlowchartSlide: React.FC<FlowchartSlideProps> = ({ slide }) => {
  const mermaidRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mermaidRef.current) {
      mermaid.initialize({ startOnLoad: true });
      
      // Use mermaid.render with the correct signature
      mermaid.render('mermaid-diagram', slide.mermaid_syntax).then((result) => {
        if (mermaidRef.current) {
          mermaidRef.current.innerHTML = result.svg;
        }
      });
    }
  }, [slide.mermaid_syntax]);

  const slideStyles: React.CSSProperties = {
    ...commonStyles,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2rem',
    height: '100%',
  };

  const titleStyles: React.CSSProperties = {
    fontSize: 'clamp(2rem, 4vw, 3.5rem)',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    textAlign: 'center',
  };

  const flowchartContainerStyles: React.CSSProperties = {
    width: '100%',
    height: '70vh',
    flex: 1,
    backgroundColor: '#f8f8f8',
    borderRadius: '10px',
    padding: '1rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    overflow: 'auto',
  };

  return (
    <div style={slideStyles}>
      <h2 style={titleStyles}>{slide.title}</h2>
      <div style={flowchartContainerStyles}>
        <div ref={mermaidRef} />
      </div>
    </div>
  );
};

export default FlowchartSlide;
