import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { Heart, Zap, CheckCircle } from 'lucide-react';
import { useAuth } from './AuthContext';
import { Question, QuizResult, UserStats } from './types';
import LoadingSpinner from './LoadingSpinner';

const QuizMasterTakeQuiz: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [currentQuestionData, setCurrentQuestionData] = useState<Question | null>(null);
  const [quizId, setQuizId] = useState<number | null>(null);
  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const [characterMood, setCharacterMood] = useState<'neutral' | 'thinking' | 'correct' | 'incorrect'>('neutral');
  const [userStats, setUserStats] = useState<UserStats>({ xp: 0, lives: 3, streak: 0, timeUntilNextLife: null });
  const [userStatsLoaded, setUserStatsLoaded] = useState(false);
  const [simulatedStats, setSimulatedStats] = useState({ xp: 0, lives: 3, streak: 0 });
  const [timeRemaining, setTimeRemaining] = useState<number>(90);
  const [timerActive, setTimerActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isWarningTime, setIsWarningTime] = useState(false);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
  const [anonymousId, setAnonymousId] = useState<string | null>(null);
  const [explanation, setExplanation] = useState<string | null>(null);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [correctAnswer, setCorrectAnswer] = useState<string | null>(null);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [showCelebration, setShowCelebration] = useState(false);
  const [celebrationMessage, setCelebrationMessage] = useState('');
  const [quizTitle, setQuizTitle] = useState<string>('');
  const [quizDescription, setQuizDescription] = useState<string>('');
  const [totalQuestions, setTotalQuestions] = useState<number>(0);

  const playWarningSound = useCallback(() => {
    const audioContext = new (window.AudioContext || (window as any).WebkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();
    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(440, audioContext.currentTime);
    gainNode.gain.setValueAtTime(0, audioContext.currentTime);
    gainNode.gain.linearRampToValueAtTime(0.5, audioContext.currentTime + 0.1);
    gainNode.gain.linearRampToValueAtTime(0, audioContext.currentTime + 0.5);
    oscillator.start(audioContext.currentTime);
    oscillator.stop(audioContext.currentTime + 0.5);
  }, []);

  const playAnswerSound = useCallback((isCorrect: boolean) => {
    const audioContext = new (window.AudioContext || (window as any).WebkitAudioContext)();
    const masterGain = audioContext.createGain();
    masterGain.connect(audioContext.destination);
    masterGain.gain.setValueAtTime(0.7, audioContext.currentTime);
    const playNote = (frequency: number, startTime: number, duration: number) => {
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();
      oscillator.connect(gainNode);
      gainNode.connect(masterGain);
      oscillator.type = 'sine';
      oscillator.frequency.setValueAtTime(frequency, startTime);
      gainNode.gain.setValueAtTime(0, startTime);
      gainNode.gain.linearRampToValueAtTime(1, startTime + 0.01);
      gainNode.gain.linearRampToValueAtTime(0, startTime + duration - 0.01);
      oscillator.start(startTime);
      oscillator.stop(startTime + duration);
    };
    const now = audioContext.currentTime;
    if (isCorrect) {
      playNote(523.25, now, 0.15);
      playNote(659.25, now + 0.15, 0.15);
      playNote(783.99, now + 0.3, 0.3);
    } else {
      playNote(392.00, now, 0.15);
      playNote(349.23, now + 0.15, 0.15);
      playNote(329.63, now + 0.3, 0.3);
    }
    setTimeout(() => masterGain.disconnect(), 1000);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      const storedAnonymousId = localStorage.getItem('anonymousId');
      if (storedAnonymousId) {
        setAnonymousId(storedAnonymousId);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const initializeQuiz = async () => {
      try {
        const urlParams = new URLSearchParams(location.search);
        const conceptId = urlParams.get('concept_id');
        const domainId = urlParams.get('domain_id');
        const certId = urlParams.get('cert_id');
        const numQuestions = urlParams.get('num_questions') || '10';

        const response = await axios.post(
          `${config.API_URL}/quiz/initialize_dynamic_quiz`,
          {
            concept_id: conceptId,
            domain_id: domainId,
            cert_id: certId,
            num_questions: parseInt(numQuestions, 10)
          },
          { withCredentials: true }
        );

        setQuizId(response.data.quiz_id);
        setQuizTitle(response.data.title);
        setQuizDescription(response.data.description);
        setTotalQuestions(response.data.num_questions);

        if (!isAuthenticated && response.data.anonymous_id) {
          setAnonymousId(response.data.anonymous_id);
          localStorage.setItem('anonymousId', response.data.anonymous_id);
        }
      } catch (error) {
        console.error('Error initializing quiz:', error);
        setError('Failed to initialize quiz. Please try again.');
      }
    };
    initializeQuiz();
  }, [location.search, isAuthenticated]);

  useEffect(() => {
    const fetchNextQuestion = async () => {
      if (quizId === null) return;
      try {
        const response = await axios.get<Question | { message: string }>(
          `${config.API_URL}/quiz/${quizId}/next-question`,
          { withCredentials: true }
        );
        if ('message' in response.data && response.data.message === 'Quiz completed') {
          navigate(`/quiz/${quizId}/results`);
        } else {
          setCurrentQuestionData(response.data as Question);
          setTimeRemaining(90);
          setTimerActive(true);
          setIsWarningTime(false);
          setCurrentQuestionNumber(prev => prev + 1);
        }
      } catch (error) {
        console.error('Error fetching next question:', error);
        setError('Failed to fetch the next question. Please try again.');
      }
    };
    fetchNextQuestion();
  }, [quizId, navigate]);

  useEffect(() => {
    if (!isAuthenticated) {
      setUserStatsLoaded(true);
      return;
    }
    const fetchUserStats = async () => {
      try {
        const response = await axios.get<UserStats>(`${config.API_URL}/user/stats`, {
          withCredentials: true
        });
        setUserStats(response.data);
        setUserStatsLoaded(true);
      } catch (error) {
        console.error('Error fetching user stats:', error);
        setUserStatsLoaded(true);
      }
    };
    fetchUserStats();
    const intervalId = setInterval(fetchUserStats, 60000);
    return () => clearInterval(intervalId);
  }, [isAuthenticated]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timerActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          const newTime = prevTime - 1;
          if (newTime === 30) {
            setIsWarningTime(true);
            playWarningSound();
          }
          return newTime;
        });
      }, 1000);
    } else if (timeRemaining === 0) {
      handleTimerExpired();
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timerActive, timeRemaining, playWarningSound]);

  const handleTimerExpired = async () => {
    setTimerActive(false);
    setIsAnswerChecked(true);
    setCharacterMood('incorrect');
    try {
      const response = await axios.post<QuizResult>(
        `${config.API_URL}/quiz/${quizId}/answer`,
        {
          question_id: currentQuestionData!.id,
          answer: 'TIME_OUT',
          anonymous_id: anonymousId
        },
        { withCredentials: true }
      );
      const result = response.data;
      if (isAuthenticated && result.new_xp_total !== undefined) {
        setUserStats(prevStats => ({
          ...prevStats,
          xp: result.new_xp_total!,
          lives: result.lives!,
          streak: 0
        }));
      } else {
        setSimulatedStats(prevStats => ({
          ...prevStats,
          lives: Math.max(0, prevStats.lives - 1),
          streak: 0
        }));
      }
      playAnswerSound(false);
      setTimeout(() => {
        setIsAnswerChecked(false);
        setCharacterMood('neutral');
        setIsWarningTime(false);
        if (!result.quiz_completed) {
          fetchNextQuestion();
        } else {
          navigate(`/quiz/${quizId}/results`);
        }
      }, 2000);
    } catch (error) {
      console.error('Error handling timer expiration:', error);
      setError('Failed to process the timed-out question. Please try again.');
      setTimeout(() => {
        setIsAnswerChecked(false);
        setCharacterMood('neutral');
        setIsWarningTime(false);
        fetchNextQuestion();
      }, 2000);
    }
  };

  const handleAnswerSelect = async (answer: string) => {
    if (!isAnswerChecked && currentQuestionData && quizId !== null) {
      setSelectedAnswer(answer);
      setTimerActive(false);
      setIsWarningTime(false);
      setIsAnswerChecked(true);
      setCharacterMood('thinking');
      setShowAnimation(true);
      try {
        const response = await axios.post<QuizResult>(
          `${config.API_URL}/quiz/${quizId}/answer`,
          {
            question_id: currentQuestionData.id,
            answer: answer,
            anonymous_id: anonymousId
          },
          { withCredentials: true }
        );
        const result = response.data;
        if (!result.is_correct) {
          setExplanation(result.explanation || null);
          setCorrectAnswer(result.correct_answer || null);
        }
        if (isAuthenticated && result.new_xp_total !== undefined) {
          setUserStats(prevStats => ({
            ...prevStats,
            xp: result.new_xp_total!,
            lives: result.lives!,
            streak: result.streak!
          }));
        } else {
          setSimulatedStats(prevStats => ({
            xp: prevStats.xp + (result.is_correct ? 10 : 0),
            lives: Math.max(0, prevStats.lives - (result.is_correct ? 0 : 1)),
            streak: result.is_correct ? prevStats.streak + 1 : 0
          }));
        }
        playAnswerSound(result.is_correct);
        setTimeout(() => {
          setShowAnimation(false);
          setCharacterMood(result.is_correct ? 'correct' : 'incorrect');
          if (result.is_correct) {
            setShowCelebration(true);
            setCelebrationMessage(`Correct!\n\n${answer}`);
            
            if (result.streak === 3) {
              setCelebrationMessage(`Hot Streak! 3 in a row!\n\n${answer}`);
            } else if (result.streak === 5) {
              setCelebrationMessage(`Incredible! 5 in a row!\n\n${answer}`);
            }

            setTimeout(() => {
              setShowCelebration(false);
              goToNextQuestion(result.quiz_completed);
            }, 2000);
          } else {
            setShowContinueButton(true);
          }
        }, 50);
      } catch (error) {
        console.error('Error submitting answer:', error);
        setError('Failed to submit answer. Please try again.');
        setIsAnswerChecked(false);
        setCharacterMood('neutral');
        setShowAnimation(false);
      }
    }
  };

  const goToNextQuestion = (quizCompleted: boolean) => {
    if (!quizCompleted) {
      setIsAnswerChecked(false);
      setCharacterMood('neutral');
      setExplanation(null);
      setShowContinueButton(false);
      fetchNextQuestion();
    } else {
      navigate(`/quiz/${quizId}/results`);
    }
  };

  const handleContinue = () => {
    goToNextQuestion(false);
  };

  const fetchNextQuestion = async () => {
    if (quizId === null) return;
    try {
      const response = await axios.get<Question | { message: string }>(
        `${config.API_URL}/quiz/${quizId}/next-question`,
        { withCredentials: true }
      );
      if ('message' in response.data && response.data.message === 'Quiz completed') {
        navigate(`/quiz/${quizId}/results`);
      } else {
        setCurrentQuestionData(response.data as Question);
        setTimeRemaining(90);
        setTimerActive(true);
      }
    } catch (error) {
      console.error('Error fetching next question:', error);
      setError('Failed to fetch the next question. Please try again.');
    }
  };

  const formatTimeUntilNextLife = (seconds: number | null) => {
    if (seconds === null) return 'Full';
    if (seconds <= 0) return 'Now';
    const minutes = Math.ceil(seconds / 60);
    return `${minutes}m`;
  };

  const ProgressBar: React.FC<{ progress: number }> = ({ progress }) => (
    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
      <div
        className="bg-blue-600 h-2.5 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!currentQuestionData || !userStatsLoaded) {
    return <LoadingSpinner />;
  }

  const parsedOptions = JSON.parse(currentQuestionData.options);

  return (
    <div className="max-w-2xl mx-auto p-4 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-2">{quizTitle}</h1>
      <p className="text-gray-600 mb-4">{quizDescription}</p>
      {!isAuthenticated && (
        <ProgressBar progress={(currentQuestionNumber / totalQuestions) * 100} />
      )}
      {(isAuthenticated || !isAuthenticated) && (
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-4">
            <div className="flex items-center">
              <Zap className="text-yellow-500 mr-1" />
              <span>XP: {isAuthenticated ? userStats.xp : simulatedStats.xp}</span>
            </div>
            <div className="flex items-center">
              <Heart className="text-red-500 mr-1" />
              <span>Lives: {isAuthenticated ? userStats.lives : simulatedStats.lives} ({isAuthenticated ? formatTimeUntilNextLife(userStats.timeUntilNextLife) : 'Full'})</span>
            </div>
            <div className="flex items-center">
              <CheckCircle className="text-green-500 mr-1" />
              <span>Streak: {isAuthenticated ? userStats.streak : simulatedStats.streak}</span>
            </div>
          </div>
          <div className="text-xl font-bold text-blue-600">
            {Math.floor(timeRemaining / 60)}:{(timeRemaining % 60).toString().padStart(2, '0')}
          </div>
        </div>
      )}
      <div className="flex justify-center mb-6">
        <div className={`w-24 h-24 rounded-full flex items-center justify-center transition-all duration-300 ${
          characterMood === 'thinking' ? 'bg-yellow-500' :
          characterMood === 'correct' ? 'bg-green-500' :
          characterMood === 'incorrect' ? 'bg-red-500' :
          'bg-blue-500'
        }`}>
          <span className="text-4xl">
          {characterMood === 'thinking' ? '🤔' :
             characterMood === 'correct' ? '😃' :
             characterMood === 'incorrect' ? '😢' :
             '🦊'}
          </span>
        </div>
      </div>
      <h2 className="text-2xl font-bold mb-6 text-gray-800">
        {currentQuestionData.text}
      </h2>
      {!isAnswerChecked ? (
        <div className="space-y-4 mb-6">
          {parsedOptions.map((option: string, index: number) => (
            <button
              key={index}
              className={`w-full p-4 text-left rounded-lg transition-colors duration-200 ease-in-out ${
                isAnswerChecked ? 'bg-gray-100' : 'bg-gray-100 hover:bg-gray-200'
              }`}
              onClick={() => handleAnswerSelect(option)}
              disabled={isAnswerChecked}
            >
              {option}
            </button>
          ))}
        </div>
      ) : (
        <div className="mb-6">
          {characterMood === 'incorrect' && (
            <div className="p-4 rounded-lg bg-red-100">
              <p className="font-semibold">Your answer: {selectedAnswer}</p>
              <p className="font-semibold mt-2">Correct answer: {correctAnswer}</p>
            </div>
          )}
          {showContinueButton && (
            <button
              className="mt-4 w-full p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
              onClick={handleContinue}
            >
              Continue
            </button>
          )}
          {explanation && (
            <div className="mt-4 p-4 bg-yellow-100 rounded-lg">
              <h3 className="text-lg font-semibold mb-2">Explanation:</h3>
              <p>{explanation}</p>
            </div>
          )}
        </div>
      )}
      {showAnimation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="text-6xl text-white">
            Thinking...
          </div>
        </div>
      )}
      {showCelebration && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-green-500 text-white p-6 rounded-lg shadow-lg max-w-md">
            <h2 className="text-3xl font-bold mb-4">{celebrationMessage.split('\n\n')[0]}</h2>
            <p className="text-xl">{celebrationMessage.split('\n\n')[1]}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizMasterTakeQuiz;
