import React, { useState, useRef, useEffect } from 'react';
import { Shield, Clock, Settings, AlertTriangle, Lock } from 'lucide-react';

interface MetricGroup {
  name: string;
  color: string;
  icon: React.ElementType;
  metrics: string[];
}

interface AnimationState {
  isExpanding: boolean;
  progress: number;
}

const CVSSInfoGraphic: React.FC = () => {
  const [activeGroup, setActiveGroup] = useState<string | null>(null);
  const [animation, setAnimation] = useState<AnimationState>({ isExpanding: false, progress: 0 });
  const animationRef = useRef<number | null>(null);

  const metricGroups: MetricGroup[] = [
    {
      name: 'Base Metrics',
      color: '#3B82F6',
      icon: Shield,
      metrics: ['Attack Vector', 'Attack Complexity', 'Privileges Required', 'User Interaction', 'Scope', 'Confidentiality', 'Integrity', 'Availability']
    },
    {
      name: 'Temporal Metrics',
      color: '#F97316',
      icon: Clock,
      metrics: ['Exploit Code Maturity', 'Remediation Level', 'Report Confidence']
    },
    {
      name: 'Environmental Metrics',
      color: '#22C55E',
      icon: Settings,
      metrics: ['Attack Vector (Modified)', 'Attack Complexity (Modified)', 'Privileges Required (Modified)', 'User Interaction (Modified)', 'Scope (Modified)', 'Confidentiality (Modified)', 'Integrity (Modified)', 'Availability (Modified)', 'Confidentiality Requirement', 'Integrity Requirement', 'Availability Requirement']
    },
    {
      name: 'Impact Metrics',
      color: '#EF4444',
      icon: AlertTriangle,
      metrics: ['Health Impact', 'Financial Impact', 'Operational Impact', 'Data Impact', 'Reputation Impact']
    },
    {
      name: 'Defense Metrics',
      color: '#8B5CF6',
      icon: Lock,
      metrics: ['Prevention Controls', 'Detection Controls', 'Response Capabilities', 'Recovery Measures', 'Resilience Factor']
    }
  ];

  const getPosition = (index: number, total: number): { x: number; y: number } => {
    const angle = ((index * (360 / total)) - 90) * (Math.PI / 180);
    const radius = 200;
    return {
      x: Math.cos(angle) * radius + 250,
      y: Math.sin(angle) * radius + 250
    };
  };

  const getInfoBoxDimensions = (metrics: string[]): { width: number; height: number } => {
    const height = Math.max(180, metrics.length * 20 + 60);
    const width = Math.max(220, metrics.reduce((max, metric) => Math.max(max, metric.length), 0) * 7 + 20);
    return { width, height };
  };

  const getInfoBoxPosition = (x: number, y: number, width: number, height: number): { x: number; y: number } => {
    const svgWidth = 500;
    const svgHeight = 500;
    const padding = 10;

    let posX = x - width / 2;
    let posY = y - height / 2;

    if (posX < padding) posX = padding;
    else if (posX + width > svgWidth - padding) posX = svgWidth - width - padding;

    if (posY < padding) posY = padding;
    else if (posY + height > svgHeight - padding) posY = svgHeight - height - padding;

    return { x: posX, y: posY };
  };

  const handleGroupClick = (groupName: string) => {
    if (activeGroup === groupName) {
      setActiveGroup(null);
      setAnimation({ isExpanding: false, progress: 0 });
    } else {
      setActiveGroup(groupName);
      setAnimation({ isExpanding: true, progress: 0 });
    }
  };

  useEffect(() => {
    if (animation.isExpanding) {
      let start: number;
      const animate = (timestamp: number) => {
        if (!start) start = timestamp;
        const progress = Math.min((timestamp - start) / 500, 1);
        setAnimation(prev => ({ ...prev, progress }));
        if (progress < 1) {
          animationRef.current = requestAnimationFrame(animate);
        }
      };
      animationRef.current = requestAnimationFrame(animate);
    } else {
      if (animationRef.current) cancelAnimationFrame(animationRef.current);
    }

    return () => {
      if (animationRef.current) cancelAnimationFrame(animationRef.current);
    };
  }, [animation.isExpanding]);

  const easeOutElastic = (x: number): number => {
    const c4 = (2 * Math.PI) / 3;
    return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
  };

  return (
    <div className="w-full h-screen bg-gray-100 flex items-center justify-center">
      <div className="relative w-[800px] h-[800px] bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-bold text-center mb-2">Common Vulnerability Scoring System (CVSS)</h1>
        <h2 className="text-xl text-center mb-8">Understanding the Five Metric Groups</h2>
        
        <svg width="100%" height="100%" viewBox="0 0 500 500">
          {/* Central CVSS Logo */}
          <circle cx="250" cy="250" r="40" fill="#E5E7EB" />
          <text x="250" y="250" textAnchor="middle" dominantBaseline="middle" fontSize="20" fontWeight="bold">CVSS</text>
          
          {/* Metric Groups */}
          {metricGroups.map((group, index) => {
            const { x, y } = getPosition(index, metricGroups.length);
            const { width, height } = getInfoBoxDimensions(group.metrics);
            const isActive = activeGroup === group.name;
            const scale = isActive ? 1 + easeOutElastic(animation.progress) * 0.2 : 1;
            const { x: infoX, y: infoY } = getInfoBoxPosition(x, y, width, height);
            
            return (
              <g key={group.name} onClick={() => handleGroupClick(group.name)}>
                <circle 
                  cx={x} 
                  cy={y} 
                  r={50 * scale} 
                  fill={group.color} 
                  className="cursor-pointer transition-all duration-300"
                >
                  <animate
                    attributeName="r"
                    from="50"
                    to={`${50 * scale}`}
                    dur="0.5s"
                    begin={isActive ? "0s" : "indefinite"}
                    fill="freeze"
                    calcMode="spline"
                    keySplines="0.19 1 0.22 1"
                  />
                </circle>
                <foreignObject x={x-25*scale} y={y-25*scale} width={50*scale} height={50*scale}>
                  <div className="flex items-center justify-center h-full">
                    <group.icon className={`w-${6*scale} h-${6*scale} text-white`} />
                  </div>
                </foreignObject>
                <text x={x} y={y+70} textAnchor="middle" fill="black" fontSize="12" fontWeight="500">{group.name}</text>
                
                {/* Info box for active group */}
                {isActive && (
                  <g>
                    <rect 
                      x={infoX} 
                      y={infoY} 
                      width={width * animation.progress} 
                      height={height * animation.progress} 
                      rx="10" 
                      fill="white" 
                      stroke={group.color} 
                      strokeWidth="2" 
                    >
                      <animate
                        attributeName="width"
                        from="0"
                        to={width.toString()}
                        dur="0.5s"
                        fill="freeze"
                        calcMode="spline"
                        keySplines="0.19 1 0.22 1"
                      />
                      <animate
                        attributeName="height"
                        from="0"
                        to={height.toString()}
                        dur="0.5s"
                        fill="freeze"
                        calcMode="spline"
                        keySplines="0.19 1 0.22 1"
                      />
                    </rect>
                    <text x={infoX + width / 2} y={infoY + 25} textAnchor="middle" fontSize="14" fontWeight="bold" fill={group.color} opacity={animation.progress}>
                      {group.name}
                      <animate
                        attributeName="opacity"
                        from="0"
                        to="1"
                        dur="0.5s"
                        fill="freeze"
                      />
                    </text>
                    {group.metrics.map((metric, i) => (
                      <text 
                        key={metric} 
                        x={infoX + 10} 
                        y={infoY + 45 + i * 18} 
                        fontSize="10" 
                        fill="black"
                        opacity={animation.progress - (i * 0.1)}
                      >
                        {metric}
                        <animate
                          attributeName="opacity"
                          from="0"
                          to="1"
                          dur="0.3s"
                          begin={`${i * 0.05}s`}
                          fill="freeze"
                        />
                      </text>
                    ))}
                  </g>
                )}
              </g>
            );
          })}
        </svg>
      </div>
    </div>
  );
};

export default CVSSInfoGraphic;
