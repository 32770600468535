// src/components/QuoteSlide.tsx
import React from 'react';
import { QuoteSlide as QuoteSlideType } from '../types';
import { commonStyles } from '../styles';

interface QuoteSlideProps {
  slide: QuoteSlideType;
}

const QuoteSlide: React.FC<QuoteSlideProps> = ({ slide }) => {
  return (
    <div style={{ ...commonStyles, textAlign: 'center' }}>
      <p
        style={{
          fontSize: 'clamp(1.5rem, 3.5vw, 3rem)',
          fontStyle: 'italic',
          marginBottom: '1rem',
        }}
      >
        "{slide.quote}"
      </p>
      <p style={{ fontSize: 'clamp(1rem, 2.5vw, 2rem)' }}>- {slide.author}</p>
    </div>
  );
};

export default QuoteSlide;
