import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { FaNetworkWired, FaShieldAlt, FaCloud, FaDatabase, FaUserLock,
  FaCog, FaFireExtinguisher, FaSearch, FaBinoculars,
  FaBalanceScale, FaClipboardCheck, FaLock, FaBug, FaCertificate } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface Certification {
  id: number;
  name: string;
  description: string;
  icon: string;
  color: string;
}

interface CertificationPathProps {
  certifications: Certification[];
  loading: boolean;
  error: string | null;
  setCurrentStep?: (step: 'paths') => void;
  showBackButton?: boolean;
}

const iconMap: { [key: string]: React.ElementType } = {
  'network-wired': FaNetworkWired,
  'shield-alt': FaShieldAlt,
  'cloud': FaCloud,
  'database': FaDatabase,
  'user-lock': FaUserLock,
  'cogs': FaCog,
  'fire-extinguisher': FaFireExtinguisher,
  'search': FaSearch,
  'binoculars': FaBinoculars,
  'balance-scale': FaBalanceScale,
  'clipboard-check': FaClipboardCheck,
  'lock': FaLock,
  'bug': FaBug,
  'certificate': FaCertificate
};

const CertificationPath: React.FC<CertificationPathProps> = ({ 
  certifications, 
  loading, 
  error, 
  setCurrentStep,
  showBackButton = true
}) => {
  const navigate = useNavigate();

  return (
    <div className="mt-8">
      <div className="flex items-center justify-between mb-6">
        {showBackButton && setCurrentStep && (
          <button
            className="flex items-center text-blue-600 hover:text-blue-800 transition-colors"
            onClick={() => setCurrentStep('paths')}
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Path Selection
          </button>
        )}
        <h2 className="text-3xl font-bold">Certification Path</h2>
        <div className="w-24"></div>
      </div>
      <div className="space-y-6">
        <h3 className="text-2xl font-semibold mb-4">Featured Certifications</h3>
        {loading ? (
          <p>Loading certifications...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {certifications.map((cert) => {
              const IconComponent = iconMap[cert.icon] || FaCertificate;
              return (
                <div
                  key={cert.id}
                  className="certification-card bg-white p-3 sm:p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                  onClick={() => navigate(`/cert/${cert.id}`)}
                >
                  <div className="flex items-center justify-center w-10 h-10 sm:w-12 sm:h-12 mb-2 sm:mb-3 rounded-full" style={{ backgroundColor: cert.color || '#4A90E2' }}>
                    <IconComponent className="text-white text-lg sm:text-xl" />
                  </div>
                  <h4 className="text-base sm:text-lg font-semibold text-gray-900 mb-1 sm:mb-2">{cert.name}</h4>
                  <p className="text-xs sm:text-sm text-gray-600 line-clamp-2">{cert.description}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CertificationPath;
