import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';
import { PieChartSlide as PieChartSlideType } from '../types';
import { commonStyles } from '../styles';

interface PieChartSlideProps {
  slide: PieChartSlideType;
}

const PieChartSlide: React.FC<PieChartSlideProps> = ({ slide }) => {
  const mermaidRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mermaidRef.current) {
      mermaid.initialize({ startOnLoad: true });

      // Add debugging
      console.log('Pie Chart Mermaid Syntax:', slide.mermaid_syntax);

      if (!slide.mermaid_syntax) {
        console.error('Mermaid syntax is undefined for slide:', slide);
        return;
      }

      try {
        mermaid.render('mermaid-diagram', slide.mermaid_syntax).then((result) => {
          if (mermaidRef.current) {
            mermaidRef.current.innerHTML = result.svg;
          }
        }).catch((error) => {
          console.error('Error rendering Mermaid diagram:', error);
        });
      } catch (error) {
        console.error('Error in Mermaid render:', error);
      }
    }
  }, [slide.mermaid_syntax]);

  const slideStyles: React.CSSProperties = {
    ...commonStyles,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2rem',
    height: '100%',
  };

  const titleStyles: React.CSSProperties = {
    fontSize: 'clamp(2rem, 4vw, 3.5rem)',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    textAlign: 'center',
  };

  const pieChartContainerStyles: React.CSSProperties = {
    width: '100%',
    height: '70vh',
    flex: 1,
    backgroundColor: '#f8f8f8',
    borderRadius: '10px',
    padding: '1rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    overflow: 'auto',
  };

  return (
    <div style={slideStyles}>
      <h2 style={titleStyles}>{slide.title}</h2>
      <div style={pieChartContainerStyles}>
        {slide.mermaid_syntax ? (
          <div ref={mermaidRef} />
        ) : (
          <p>Error: No Mermaid syntax provided for this pie chart.</p>
        )}
      </div>
    </div>
  );
};

export default PieChartSlide;
