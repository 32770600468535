// src/components/ImageSlide.tsx
import React from 'react';
import { ImageSlide as ImageSlideType } from '../types';
import { commonStyles } from '../styles';

interface ImageSlideProps {
  slide: ImageSlideType;
}

const ImageSlide: React.FC<ImageSlideProps> = ({ slide }) => {
  const slideStyles: React.CSSProperties = {
    ...commonStyles,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2rem',
    height: '100%',
  };

  const titleStyles: React.CSSProperties = {
    fontSize: 'clamp(2rem, 4vw, 3.5rem)',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    textAlign: 'center',
  };

  const imageContainerStyles: React.CSSProperties = {
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f8f8f8',
    borderRadius: '10px',
    padding: '1rem',
  };

  const imageStyles: React.CSSProperties = {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const captionStyles: React.CSSProperties = {
    textAlign: 'center',
    fontSize: 'clamp(1rem, 2vw, 1.4rem)',
    fontStyle: 'italic',
    marginTop: '1.5rem',
    color: '#555',
  };

  return (
    <div style={slideStyles}>
      <h2 style={titleStyles}>{slide.title}</h2>
      <div style={imageContainerStyles}>
        <img
          src={slide.imageUrl}
          alt={slide.title}
          style={imageStyles}
        />
      </div>
      <p style={captionStyles}>{slide.caption}</p>
    </div>
  );
};

export default ImageSlide;
