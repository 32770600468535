import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useAuth } from './AuthContext';
import Header from './Header';
import HeaderCert from './HeaderCert';

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-semibold">{question}</span>
        <span className="text-2xl">{isOpen ? '−' : '+'}</span>
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  );
};

interface Domain {
  id: number;
  title: string;
  description: string;
  key_concepts: Array<{ id: number; name: string }>;
  question_count: number;
}

interface CertificationData {
  name: string;
  description: string;
  domains: Domain[];
  exam_details: {
    format: string;
    questions: string;
    duration: string;
    passing_score: string;
    languages: string[];
    prerequisites: string[];
    recertification: string;
  };
  salary_details: {
    global_average: number;
    regional_salaries: {
      [key: string]: number;
    };
    currency: string;
  };
  career_opportunities: {
    title: string;
    description: string;
  }[];
  faqs: {
    question: string;
    answer: string;
  }[];
}

interface Quiz {
  id: number;
  title: string;
  description: string;
  domain_id: number;
  num_questions: number;
}

interface DomainPerformance {
  accuracy: number;
  correct_answers: number;
  domain_coverage: number;
  domain_name: string;
  questions_attempted: number;
  total_questions: number;
  total_quiz_attempts: number;
  unique_questions_attempted: number;
}

const QuizMasterCert: React.FC = () => {
  const { certId } = useParams<{ certId: string }>();
  const [certificationData, setCertificationData] = useState<CertificationData | null>(null);
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();
  const [keyConceptIndices, setKeyConceptIndices] = useState<Record<number, number>>({});
  const [userPerformance, setUserPerformance] = useState<Record<number, DomainPerformance>>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const certResponse = await axios.get(`${config.API_URL}/quiz/cert_detail/${certId}`);
        setCertificationData(certResponse.data);
        const generatedQuizzes = certResponse.data.domains.map((domain: Domain) => ({
          id: domain.id,
          title: `${domain.title}`,
          description: `Test your knowledge on ${domain.title}`,
          domain_id: domain.id,
          num_questions: domain.question_count
        }));
        setQuizzes(generatedQuizzes);
        setKeyConceptIndices(
          certResponse.data.domains.reduce((acc: Record<number, number>, domain: Domain) => {
            acc[domain.id] = 0;
            return acc;
          }, {})
        );
        
        if (isAuthenticated) {
          const performanceResponse = await axios.get(`${config.API_URL}/quiz/user_performance/${certId}`, {
            withCredentials: true
          });
          setUserPerformance(performanceResponse.data);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, [certId, isAuthenticated]);

  useEffect(() => {
    const timer = setInterval(() => {
      setKeyConceptIndices((prevIndices) => {
        const newIndices = { ...prevIndices };
        Object.keys(newIndices).forEach((domainId) => {
          const domain = certificationData?.domains.find((d) => d.id === Number(domainId));
          if (domain) {
            newIndices[Number(domainId)] = (newIndices[Number(domainId)] + 1) % domain.key_concepts.length;
          }
        });
        return newIndices;
      });
    }, 3000);

    return () => clearInterval(timer);
  }, [certificationData]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!certificationData) return <div>No certification data found</div>;

  const { name, description, domains, exam_details, salary_details, career_opportunities, faqs } = certificationData;

  const chartData = [
    { name: 'Global', salary: salary_details.global_average },
    ...Object.entries(salary_details.regional_salaries).map(([name, salary]) => ({ name, salary }))
  ];

  return (
    <div className="bg-gray-100 font-sans">
      {isAuthenticated && <Header />}
      {!isAuthenticated && certId && <HeaderCert name={name} certId={certId} />}

      <main className="container mx-auto max-w-4xl px-4 py-8">
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-center">Study Domains</h2>
          {loading ? (
            <p className="text-center">Loading quizzes...</p>
          ) : error ? (
            <p className="text-center text-red-500">{error}</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {quizzes.map((quiz) => {
                const domain = domains.find((d) => d.id === quiz.domain_id);
                const performance = userPerformance[quiz.domain_id];
                const questionsComplete = performance ? performance.questions_attempted : 0;
                const questionsLeft = quiz.num_questions - questionsComplete;
                return (
                  <div key={quiz.id} className="bg-white p-4 rounded-lg shadow-md flex">
                    <div className="w-2/3 pr-4">
                      <h3 className="text-lg font-semibold mb-2">{quiz.title}</h3>
                      <p className="text-sm text-gray-600 mb-2">
                        Questions: {quiz.num_questions} (
                        <span className="text-green-600">{questionsComplete} complete</span>,
                        <span className="text-blue-600"> {questionsLeft} left</span>)
                      </p>
                      {isAuthenticated && performance && (
                        <div className="mt-2 space-y-2">
                          <div className="flex items-center">
                            <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span className="text-sm font-medium">Accuracy:</span>
                            <span className="ml-auto text-sm font-semibold text-blue-600">{performance.accuracy.toFixed(2)}%</span>
                          </div>
                          <div className="flex items-center">
                            <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                            </svg>
                            <span className="text-sm font-medium">Coverage:</span>
                            <span className="ml-auto text-sm font-semibold text-green-600">{performance.domain_coverage.toFixed(2)}%</span>
                          </div>
                        </div>
                      )}
                      <div className="mt-2 space-x-2">
                      <Link
                  to={`https://certit.ai/domain/${quiz.domain_id}`}
                  className="bg-green-500 text-white px-3 py-2 rounded text-sm hover:bg-green-600 transition duration-300 inline-block"
                >
                  Study
                </Link>
                <Link
                  to={`/quiz/take?domain_id=${quiz.domain_id}&cert_id=${certId}&num_questions=10`}
                  className="bg-blue-500 text-white px-3 py-2 rounded text-sm hover:bg-blue-600 transition duration-300 inline-block"
                >
                  Quiz
                </Link>
                <Link
                  to={`/quiz/take?domain_id=${quiz.domain_id}&cert_id=${certId}&num_questions=25`}
                  className="bg-purple-500 text-white px-3 py-2 rounded text-sm hover:bg-purple-600 transition duration-300 inline-block"
                >
                  Exam
                </Link>

                      </div>
                    </div>
                    <div className="w-1/3">
                      <div className="bg-blue-100 p-2 rounded-lg h-full flex flex-col">
                        <h4 className="text-sm font-semibold mb-1">Key Concepts:</h4>
                        <div className="flex-grow overflow-hidden relative">
                          <div className="animate-scroll absolute inset-0">
                          {domain?.key_concepts.map((concept, index) => (
                            <Link
                              key={index}
                              to={`/slideshow/${concept.id}`}
                              className={`block px-2 py-1 rounded text-xs mb-1 ${
                                index === keyConceptIndices[domain.id] ? 'bg-blue-500 text-white' : 'bg-blue-200'
                              } hover:bg-blue-300 transition duration-300`}
                            >
                              {concept.name}
                            </Link>
                          ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-center">{name} Salary Insights</h2>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <p className="text-lg mb-4 text-center">
              {name}-certified professionals earn an average of ${salary_details.global_average.toLocaleString()} {salary_details.currency} per year globally.
            </p>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="salary" fill="#4299e1" />
              </BarChart>
            </ResponsiveContainer>
            <p className="text-sm text-gray-600 mt-2 text-center">
              Boost your earning potential with {name} certification and Certit's expert preparation.
            </p>
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-center">Career Opportunities</h2>
          <p className="text-lg mb-6 text-center">With {name} certification and Certit's comprehensive preparation, you'll be ready for high-level careers such as:</p>
          <div className="grid md:grid-cols-2 gap-6">
            {career_opportunities.map((career, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-3">{career.title}</h3>
                <p className="text-gray-700">{career.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-center">Frequently Asked Questions</h2>
          <div className="bg-white p-6 rounded-lg shadow-md">
            {faqs.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
          </div>
        </section>

        {!isAuthenticated && (
          <section className="text-center">
            <h2 className="text-3xl font-semibold mb-6">Ready to Become {name} Certified?</h2>
            <p className="text-lg mb-6">Join Certit today and start your journey to {name} success!</p>
            <div className="space-x-4">
              <Link
                to={`/register?cert_id=${certId}`}
                className="bg-blue-600 text-white px-8 py-3 rounded-full text-xl font-semibold hover:bg-blue-700 transition duration-300"
              >
                Start Free
                </Link>
              <Link to="/pro-signup" className="bg-green-500 text-white px-8 py-3 rounded-full text-xl font-semibold hover:bg-green-600 transition duration-300">
                Go Pro
              </Link>
            </div>
          </section>
        )}
      </main>

      <footer className="bg-gray-800 text-white py-8 mt-16">
        <div className="container mx-auto max-w-4xl px-4 text-center">
          <p>&copy; 2022 Certit - Your Path to {name} Certification. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default QuizMasterCert;