import React from 'react';
import { Link } from 'react-router-dom';

interface HeaderCertProps {
  name: string;
  certId: string;  // Add this line
}

const HeaderCert: React.FC<HeaderCertProps> = ({ name, certId }) => {  // Add certId here
  return (
    <header className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-16 px-4">
      <div className="container mx-auto max-w-6xl">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-5xl font-extrabold mb-4 leading-tight">
              Ace Your <span className="text-yellow-300">{name}</span> Exam with Certit
            </h1>
            <p className="text-xl mb-6 text-indigo-100">
              Master Information Security and Skyrocket Your Career
            </p>
            <div className="flex space-x-4">
              <Link
                to={`/register?cert_id=${certId}`}  // Update this line
                className="bg-yellow-400 text-indigo-900 px-8 py-3 rounded-lg text-lg font-semibold hover:bg-yellow-300 transition duration-300 shadow-lg"
              >
                Free Account
              </Link>
              <Link
                to="/learn-more"
                className="bg-transparent border-2 border-white text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-white hover:text-indigo-600 transition duration-300"
              >
                Learn More
              </Link>
            </div>
          </div>
          <div className="md:w-1/2 flex justify-center">
            <div className="bg-white p-6 rounded-xl shadow-2xl text-indigo-900 max-w-sm w-full">
              <h2 className="text-2xl font-bold mb-4">Why Choose Certit?</h2>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span>Adaptive Learning Technology</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span>Real-time Progress Tracking</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span>Expert-Curated Content</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderCert;