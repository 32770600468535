// CheckEmailPage.tsx

import React from 'react';
import { CheckCircle, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const CheckEmailPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-6xl mx-auto px-4 py-4">
      <div className="flex items-center justify-between mb-6">
        <button 
          className="flex items-center text-blue-600 hover:text-blue-800 transition-colors"
          onClick={() => navigate('/')}
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Home
        </button>
        <h2 className="text-3xl font-bold">Registration Successful</h2>
        <div className="w-24"></div> {/* Spacer for alignment */}
      </div>

      <div className="text-center py-12">
        <CheckCircle className="w-24 h-24 text-green-500 mx-auto mb-6" />
        <h2 className="text-4xl font-bold mb-4">Check Your Email</h2>
        <p className="text-xl mb-8">
          We've sent a confirmation link to your email address. 
          Please check your inbox and click the link to activate your account.
        </p>
        <div className="mt-8">
          <button 
            className="px-6 py-3 bg-blue-600 text-white rounded-full text-lg hover:bg-blue-700 transition-colors"
            onClick={() => navigate('/login')}
          >
            Go to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckEmailPage;
