import React, { useState, useEffect } from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Legend } from 'recharts';

const CybersecurityProgressChart: React.FC = () => {
  const [currentWeek, setCurrentWeek] = useState(1);
  const progressData = [
    { subject: 'Network', week1: 60, week2: 70, week3: 80 },
    { subject: 'App Sec', week1: 45, week2: 55, week3: 65 },
    { subject: 'Cloud', week1: 50, week2: 60, week3: 70 },
    { subject: 'Data', week1: 55, week2: 65, week3: 75 },
    { subject: 'Identity', week1: 48, week2: 58, week3: 68 },
    { subject: 'Ops', week1: 52, week2: 62, week3: 72 },
    { subject: 'Incident', week1: 55, week2: 65, week3: 75 },
    { subject: 'Forensics', week1: 45, week2: 60, week3: 70 },
    { subject: 'Threat', week1: 43, week2: 53, week3: 63 },
    { subject: 'Risk', week1: 50, week2: 60, week3: 70 },
    { subject: 'Compliance', week1: 45, week2: 55, week3: 65 },
    { subject: 'Crypto', week1: 40, week2: 55, week3: 70 },
    { subject: 'Web Security', week1: 50, week2: 65, week3: 75 },
    { subject: 'Malware Analysis', week1: 30, week2: 45, week3: 60 }
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentWeek < 3) {
        setCurrentWeek(currentWeek + 1);
      } else {
        setCurrentWeek(1);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [currentWeek]);

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={progressData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis angle={30} domain={[0, 100]} />
          {currentWeek >= 1 && (
            <Radar name="Week 1" dataKey="week1" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
          )}
          {currentWeek >= 2 && (
            <Radar name="Week 2" dataKey="week2" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
          )}
          {currentWeek >= 3 && (
            <Radar name="Week 3" dataKey="week3" stroke="#ffc658" fill="#ffc658" fillOpacity={0.6} />
          )}
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CybersecurityProgressChart;
