// src/components/TitleSlide.tsx
import React from 'react';
import { TitleSlide as TitleSlideType } from '../types';
import { commonStyles } from '../styles';

interface TitleSlideProps {
  slide: TitleSlideType;
}

const TitleSlide: React.FC<TitleSlideProps> = ({ slide }) => {
  const slideStyles: React.CSSProperties = {
    ...commonStyles,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
    height: '100%',
  };

  const contentBoxStyles: React.CSSProperties = {
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white
    borderRadius: '10px',
    padding: '3rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '80%',
  };

  const titleStyles: React.CSSProperties = {
    fontSize: 'clamp(2rem, 5vw, 4rem)',
    fontWeight: 'bold',
    marginBottom: '1rem',
  };

  const subtitleStyles: React.CSSProperties = {
    fontSize: 'clamp(1.5rem, 3vw, 2.5rem)',
    marginBottom: '0.5rem',
  };

  const dateStyles: React.CSSProperties = {
    fontSize: 'clamp(1rem, 2vw, 1.5rem)',
  };

  return (
    <div style={slideStyles}>
      <div style={contentBoxStyles}>
        <h1 style={titleStyles}>{slide.title}</h1>
        <h2 style={subtitleStyles}>{slide.subtitle}</h2>
        <p style={dateStyles}>{slide.date}</p>
      </div>
    </div>
  );
};

export default TitleSlide;
