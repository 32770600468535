export const userProfiles = [
    {
      "name": "Alex",
      "age": 17,
      "education": "High school junior",
      "background": "Tech-savvy, enjoys gaming and coding clubs",
      "goal": "Obtain entry-level certifications to boost college applications",
      "learningStyle": "Visual learner; prefers interactive videos and gamified content"
    },
    {
      "name": "Tyler",
      "age": 20,
      "education": "Undergraduate student in Computer Science",
      "background": "Studying computer science; interested in networking",
      "goal": "Gain certifications like Cisco's CCNA to enhance job prospects",
      "learningStyle": "Analytical; prefers in-depth tutorials and lab exercises"
    },
    {
      "name": "Maria",
      "age": 35,
      "education": "Bachelor's in Business Administration",
      "background": "10 years in retail management",
      "goal": "Transition into IT by obtaining foundational certifications like CompTIA A+",
      "learningStyle": "Prefers structured courses with real-world examples"
    },
    {
      "name": "David",
      "age": 40,
      "education": "Bachelor's in Information Technology",
      "background": "15 years in IT support",
      "goal": "Acquire advanced certifications like CISSP to move into cybersecurity",
      "learningStyle": "Self-paced learning; values detailed documentation and case studies"
    },
    {
      "name": "John",
      "age": 28,
      "education": "High school diploma",
      "background": "Recently laid off from manufacturing job",
      "goal": "Quickly obtain certifications to enter the IT workforce",
      "learningStyle": "Needs affordable, fast-track courses; benefits from practice exams"
    },
    {
      "name": "Marcus",
      "age": 32,
      "education": "Some college credits",
      "background": "Experience with military communication systems",
      "goal": "Translate military experience into civilian IT certifications",
      "learningStyle": "Discipline-focused; prefers mission-oriented tasks and clear objectives"
    },
    {
      "name": "Sarah",
      "age": 45,
      "education": "Bachelor's in Literature",
      "background": "Out of the workforce for 10 years",
      "goal": "Re-skill with IT certifications to secure a flexible job",
      "learningStyle": "Needs flexible scheduling; prefers mobile-friendly content"
    },
    {
      "name": "Yuki",
      "age": 22,
      "education": "Bachelor's in Engineering from home country",
      "background": "New to the country; proficient in technology",
      "goal": "Obtain globally recognized certifications",
      "learningStyle": "Visual aids and simplified language help comprehension"
    },
    {
      "name": "Robert",
      "age": 55,
      "education": "Master's in Computer Science",
      "background": "Extensive experience in legacy systems",
      "goal": "Learn about cloud computing and get AWS certifications",
      "learningStyle": "Prefers reading materials and technical manuals"
    },
    {
      "name": "Emma",
      "age": 30,
      "education": "Associate degree in Graphic Design",
      "background": "Freelance web designer",
      "goal": "Add front-end development certifications to attract more clients",
      "learningStyle": "Hands-on projects and practical applications"
    },
    {
      "name": "Michael",
      "age": 25,
      "education": "High school diploma",
      "background": "Limited mobility; interested in technology",
      "goal": "Obtain certifications for remote IT jobs",
      "learningStyle": "Needs accessible content compatible with assistive technologies"
    },
    {
      "name": "Barbara",
      "age": 60,
      "education": "Retired educator",
      "background": "Enjoys learning new skills",
      "goal": "Gain IT certifications for personal fulfillment",
      "learningStyle": "Enjoys lectures and reading; prefers a relaxed pace"
    },
    {
      "name": "Raj",
      "age": 27,
      "education": "Bachelor's in Business Management",
      "background": "Entrepreneurial spirit; basic IT knowledge",
      "goal": "Obtain multiple certifications to enhance business credibility",
      "learningStyle": "Prefers concise, actionable information; goal-oriented"
    },
    {
      "name": "Lena",
      "age": 23,
      "education": "Enrolled in vocational training",
      "background": "Works part-time in retail",
      "goal": "Earn certifications to move into a full-time IT role",
      "learningStyle": "Needs micro-learning modules; benefits from flexible scheduling"
    },
    {
      "name": "Carlos",
      "age": 19,
      "education": "High school graduate",
      "background": "Diagnosed with ADHD",
      "goal": "Achieve IT certifications to find a suitable career path",
      "learningStyle": "Interactive content; gamified learning to maintain engagement"
    },
    {
      "name": "Priya",
      "age": 38,
      "education": "Bachelor's in Computer Science from home country",
      "background": "Experienced professional; credentials not recognized locally",
      "goal": "Obtain local IT certifications to re-enter the workforce",
      "learningStyle": "Needs language support; practical, experience-based learning"
    },
    {
      "name": "Aiden",
      "age": 29,
      "education": "Some college",
      "background": "Lives in a remote area with limited educational resources",
      "goal": "Use online resources to obtain IT certifications",
      "learningStyle": "Requires offline access to materials; downloadable content"
    },
    {
      "name": "Olivia",
      "age": 35,
      "education": "GED",
      "background": "Limited work experience",
      "goal": "Gain certifications to improve employment prospects upon release",
      "learningStyle": "Needs print-based materials due to restricted internet access"
    },
    {
      "name": "Wei",
      "age": 42,
      "education": "MBA",
      "background": "Works in finance; wants to understand IT systems",
      "goal": "Obtain foundational IT certifications",
      "learningStyle": "Prefers high-level overviews; minimal technical jargon"
    },
    {
      "name": "Jamal",
      "age": 16,
      "education": "High school sophomore",
      "background": "Limited access to technology; eager to learn",
      "goal": "Use certifications to create better opportunities",
      "learningStyle": "Needs engaging, accessible content; mobile-compatible"
    },
    {
      "name": "Lisa",
      "age": 26,
      "education": "Bachelor's in Information Systems",
      "background": "Communicates via sign language",
      "goal": "Obtain certifications to work in IT support",
      "learningStyle": "Prefers text-based content; videos with captions"
    },
    {
      "name": "Alex",
      "age": 33,
      "education": "Bachelor's in Liberal Arts",
      "background": "Travels frequently; works remotely",
      "goal": "Gain certifications to secure remote IT contracts",
      "learningStyle": "Needs mobile-friendly content; adaptable to different time zones"
    },
    {
      "name": "Jordan",
      "age": 24,
      "education": "Bachelor's in Computer Science",
      "background": "Looking for inclusive learning spaces",
      "goal": "Obtain certifications while being part of a supportive community",
      "learningStyle": "Values collaborative learning; forums and discussion groups"
    },
    {
      "name": "Samantha",
      "age": 37,
      "education": "Bachelor's in Engineering",
      "background": "Limited time due to demanding job",
      "goal": "Quickly pass certification exams required for promotion",
      "learningStyle": "Time-efficient methods; prefers summaries and practice tests"
    },
    {
      "name": "Ethan",
      "age": 29,
      "education": "Master's in Mathematics",
      "background": "Strong analytical skills; new to IT certifications",
      "goal": "Obtain certifications in data analytics and machine learning",
      "learningStyle": "Prefers data-driven content; enjoys working on real datasets"
    },
    {
      "name": "George",
      "age": 65,
      "education": "Retired software engineer",
      "background": "Wants to stay mentally active and up-to-date",
      "goal": "Update skills with the latest IT certifications",
      "learningStyle": "Enjoys self-paced learning; prefers reading and webinars"
    },
    {
      "name": "Emily",
      "age": 31,
      "education": "Associate degree in IT",
      "background": "Balancing work and childcare",
      "goal": "Obtain advanced certifications to secure a better-paying job",
      "learningStyle": "Needs on-demand learning; benefits from short, focused lessons"
    },
    {
      "name": "Thomas",
      "age": 45,
      "education": "Bachelor's in Marketing",
      "background": "Returning to work after a long-term illness",
      "goal": "Update skills and obtain IT certifications for a fresh start",
      "learningStyle": "Needs adjustable pacing; prefers gentle progression"
    },
    {
      "name": "Rahim",
      "age": 22,
      "education": "Self-taught programmer",
      "background": "Limited access to formal education; high interest in IT",
      "goal": "Obtain affordable certifications recognized internationally",
      "learningStyle": "Prefers online, low-bandwidth options; open-source materials"
    },
    {
      "name": "Sophie",
      "age": 34,
      "education": "Bachelor's in Information Technology",
      "background": "Uses screen readers; adept at navigating technology",
      "goal": "Obtain certifications to work in accessibility tech",
      "learningStyle": "Needs content compatible with screen readers; audio descriptions"
    },
    {
      "name": "Daniel",
      "age": 39,
      "education": "PhD in Computer Science",
      "background": "Expert in software development",
      "goal": "Obtain advanced certifications in artificial intelligence",
      "learningStyle": "Prefers deep dives into complex topics; research-oriented"
    },
    {
      "name": "Jessica",
      "age": 27,
      "education": "Bachelor's in Information Systems",
      "background": "Current role mandates certification for compliance",
      "goal": "Quickly pass required certification exams",
      "learningStyle": "Focused on exam prep; values practice questions and summaries"
    },
    {
      "name": "Liam",
      "age": 30,
      "education": "Bachelor's in Environmental Science",
      "background": "Wants to leverage IT in environmental projects",
      "goal": "Obtain certifications in data analysis and GIS technologies",
      "learningStyle": "Application-based learning; appreciates case studies"
    },
    {
      "name": "Karen",
      "age": 40,
      "education": "High school diploma",
      "background": "Unemployed due to economic downturn",
      "goal": "Retrain in IT to secure stable employment",
      "learningStyle": "Needs affordable resources; straightforward, practical content"
    },
    {
      "name": "Hiroshi",
      "age": 26,
      "education": "Bachelor's in International Relations",
      "background": "Fluent in multiple languages",
      "goal": "Obtain globally recognized IT certifications for international work",
      "learningStyle": "Prefers content available in various languages; culturally diverse materials"
    },
    {
      "name": "Zoe",
      "age": 24,
      "education": "Part-time college student",
      "background": "Works full-time; studies in evenings",
      "goal": "Obtain certifications to accelerate career advancement",
      "learningStyle": "Needs flexible scheduling; benefits from mobile access"
    },
    {
      "name": "Amir",
      "age": 31,
      "education": "Bachelor's in Computer Engineering (credentials not recognized)",
      "background": "Displaced due to conflict; starting anew",
      "goal": "Obtain certifications to re-enter the IT field",
      "learningStyle": "Requires supportive resources; may need language assistance"
    },
    {
      "name": "Ryan",
      "age": 21,
      "education": "High school graduate",
      "background": "Exceptional attention to detail; struggles with social interactions",
      "goal": "Obtain IT certifications suited to strengths in analysis",
      "learningStyle": "Prefers structured, logical content; minimal group work"
    },
    {
      "name": "Isabella",
      "age": 33,
      "education": "Bachelor's in Fine Arts",
      "background": "Wants to integrate IT into artistic projects",
      "goal": "Obtain certifications in multimedia and web development",
      "learningStyle": "Visual and hands-on learning; enjoys creative projects"
    },
    {
      "name": "Michael",
      "age": 50,
      "education": "MBA",
      "background": "High-level management; oversees IT departments",
      "goal": "Obtain certifications to make informed strategic decisions",
      "learningStyle": "Prefers executive summaries; values strategic insights over technical details"
    }
  ]
