import React, { useState } from 'react';
import LearnerProfileSelector from './LearnerProfileSelector';
import Button from '../components/ui/button';
import { userProfiles } from '../data/userProfiles'; // You'll need to create this file

interface UserProfile {
  name: string;
  age: number;
  education: string;
  background: string;
  goal: string;
  learningStyle: string;
}

const LearnerProfilePage: React.FC = () => {
  const [selectedProfile, setSelectedProfile] = useState<UserProfile | null>(null);

  const handleProfileSelect = (profile: UserProfile) => {
    setSelectedProfile(profile);
  };

  const handleContinue = () => {
    // Here you would implement the logic to continue with the selected profile
    // For example, you might save it to state or localStorage, or redirect to another page
    console.log('Continuing with profile:', selectedProfile);
    // TODO: Implement your logic here
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Certit Learner Profiles</h1>
      
      <p className="mb-4">Select a learner profile that best matches your situation. This will help us personalize your learning experience.</p>
      
      <LearnerProfileSelector profiles={userProfiles} onSelect={handleProfileSelect} />
      
      {selectedProfile && (
        <div className="mt-6">
          <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
            <p className="font-bold">Profile Selected</p>
            <p>You've selected {selectedProfile.name}'s profile. We'll use this information to personalize your learning experience.</p>
          </div>
          <Button onClick={handleContinue}>Continue with This Profile</Button>
        </div>
      )}
    </div>
  );
};

export default LearnerProfilePage;